import React, { Component } from "react";
class PrivacyPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="page-body container-fluid">
        <div className="container-fluid main-title text-uppercase">
          <div className="row">
            <div className="col-12">Privacy Policy</div>
          </div>
          <hr />
        </div>

        <div className="row">
          <div className="col-12 text-left px-5 py-1">
            <p>
              KLOVER is incorporated in Sri Lanka carrying on business as a
              clothing brand and operates www.klover.lk. This page informs you
              of our policies regarding the collection, use and disclosure of
              Personal Information we receive from users of the site.
            </p>
            <p>
              We use your personal information only for providing a satisfactory
              service. By using our site and services, you agree to the
              collection and use of the data that we collect in accordance with
              this Privacy Policy.
            </p>
            <p>
              While using our website, we may ask you to provide us with certain
              personally identifiable information including your name, address,
              telephone number and email address that can be used to contact you
              to process the task which you visited the website, together with
              data about your use of the website.
            </p>
            <p>
              KLOVER values your privacy and act to ensure that it is protected.
              So, we do not share your personal information with any other
              company or party.
            </p>
            <p>
              We are available to answer any customer questions regarding our
              privacy policy and we may change this policy from time to time by
              updating this page. You should check this page from time to time
              to ensure that you are happy with any changes.
            </p>
            <small>This policy is effective from 20.11.2019.</small>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
