import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authAction";
import "../../css/Navbar.css";

class Navbar extends Component {
  state = { isLogedIn: false };
  render() {
    return (
      <nav
        id="adminNav"
        className="navbar navbar-expand-md navbar-dark bg-dark py-1 px-auto fixed-top "
      >
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <Link to="/" className="text-center">
          <img
            className="nav-logo img-fluid "
            src="/img/logo_white.png"
            alt="klover"
            style={{ maxWidth: "150px", height: "40px", margin: "3px" }}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent2"
          aria-controls="navbarSupportedContent2"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-user fa-lg p-1" />
        </button>
        <div
          className="collapse flex-column navbar-collapse m-auto"
          id="navbarSupportedContent"
          data-parent="#adminNav"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
        >
          <div className="d-flex justify-content-center w-100">
            <Link
              to="/admin/addproduct"
              className="m-auto pt-2 nav-link text-center"
            >
              Add Products
            </Link>
            <Link
              to="/admin/updateproduct"
              className="m-auto pt-2 nav-link text-center"
            >
              Update Products
            </Link>
            <Link to="/admin/" className="m-auto pt-2 nav-link text-center">
              Manage Orders
            </Link>
            <Link
              to="/admin/customize"
              className="m-auto pt-2 nav-link text-center"
            >
              Customize Site
            </Link>
          </div>
        </div>

        <div
          className="collapse flex-column navbar-collapse m-auto nav-c-item"
          id="navbarSupportedContent2"
          data-parent="#adminNav"
          data-toggle="collapse"
          data-target="#navbarSupportedContent2"
          aria-controls="navbarSupportedContent2"
        >
          <div className="d-flex justify-content-center">
            <div className="nav-dropdown">
              <button className="p-2 nav-dropbtn">
                <i className="fas fa-user fa-lg" />
              </button>
              <div
                style={{ width: "100px" }}
                className="nav-dropdown-content dropdown-menu-right"
              >
                {this.props.isLoggedIn ? (
                  <button
                    onClick={this.props.logoutUser}
                    className="dropdown-item btn-dark btn"
                  >
                    Log Out
                  </button>
                ) : (
                  <Link to="/auth" className="dropdown-item btn-dark btn">
                    Login / Signup
                  </Link>
                )}
                {
                  // <div className="dropdown-divider"></div>
                  // <Link to="/profile" className="dropdown-item disabled">
                  //   Profile
                  // </Link>
                  // <Link to="/orders" className="dropdown-item disabled">
                  //   Order history
                  // </Link>
                }
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn
});

export default connect(mapStateToProps, { logoutUser })(Navbar);
