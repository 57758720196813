import React, { Component } from "react";
class Deafault extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="page-body container-fluid">
        <div className="container-fluid main-title text-uppercase">
          <div className="row">
            <div className="col-12">Page Not Found</div>
          </div>
          <hr />
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <h6>Sorry, The page you requested does not exist...</h6>
          </div>
        </div>
      </div>
    );
  }
}

export default Deafault;
