import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

class TrackOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: this.props.match.params.id ? this.props.match.params.id : "",
      isOrderFound: false,
      searching: false,
      data: { orderStatus: "", waybill: "" },
    };
    this.handleSearch = this.handleSearch.bind(this);
    if (this.props.match.params.id) {
      this.getData(this.props.match.params.id);
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleSearch(e) {
    e.preventDefault();
    this.props.history.push("/ordertracking/" + this.state.orderId);
    this.getData(this.state.orderId);
  }
  getData(id) {
    axios
      .get("/orders/waybill/" + id)
      .then((response) => {
        if (response.data.orderStatus) {
          this.setState({
            isOrderFound: true,
            searching: true,
            data: response.data,
          });
        } else {
          this.setState({ isOrderFound: false, searching: true });
        }
      })
      .catch((err) => {
        this.setState({ isOrderFound: false, searching: true });
        console.log(err);
      });
  }
  render() {
    return (
      <div className="page-body container-fluid delivery">
        <div className="container-fluid main-title text-uppercase">
          <div className="row">
            <div className="col-12">Track Your Order</div>
          </div>
          <hr />
        </div>
        <form onSubmit={this.handleSearch}>
          <div className="row mt-4 text-center">
            <div className="col-12 text-center px-5 m-auto">
              <div className="search-box mt-1 m-auto">
                <input
                  type="text"
                  className="search-txt"
                  placeholder="Order Number"
                  value={this.state.orderId}
                  onChange={(e) =>
                    this.setState({
                      orderId: e.target.value.trim().toUpperCase(),
                    })
                  }
                />
                <button
                  type="submit"
                  style={{ border: "none" }}
                  className="search-btn"
                >
                  <i className="fas fa-search" />
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="row mt-3 w-75 mx-auto">
          {this.state.searching ? (
            this.state.isOrderFound ? (
              <div
                className="col-12 px-5"
                style={{
                  width: "60%",
                  border: "solid 1px green",
                  borderRadius: "20px",
                  margin: "auto",
                  padding: "1rem",
                }}
              >
                <div className="row text-left">
                  <div className="col-7 my-auto">
                    Order status:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.state.data.orderStatus}
                    </span>
                  </div>
                  {this.state.data.orderStatus === "On Delivery" ||
                  this.state.data.orderStatus === "Completed" ? (
                    <div className="col-4">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          "http://domex.lk/tracking.php?wbno=" +
                          this.state.data.waybill
                        }
                        className="btn btn-dark"
                      >
                        <i className="fas fa-truck"></i> Track Here
                      </a>
                    </div>
                  ) : null}

                  <div className="col-1 my-auto">
                    <Link to="/contactus">
                      <i
                        style={{ fontSize: "30px" }}
                        className="fas fa-question-circle"
                      ></i>
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="col-12 px-5"
                style={{
                  width: "60%",
                  border: "solid 1px red",
                  borderRadius: "20px",
                  margin: "auto",
                  padding: "1rem",
                }}
              >
                Order not found
              </div>
            )
          ) : null}
        </div>
      </div>
    );
  }
}

export default TrackOrder;
