import { combineReducers } from "redux";
import authReducer from "./auth";
import addToCartReducer from "./addToCart";

const allReducers = combineReducers({
  auth: authReducer,
  addToCart: addToCartReducer
});

export default allReducers;
