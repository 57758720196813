import { LOGIN_USER, LOGOUT_USER } from "../actions/types";

const initialState = {
  currentUser: {
    user_id: "",
    is_admin: true,
    name: "",
  },
  isLoggedIn: true,
  allreadyexist: false,
  userNotFound: false,
  loginSuccess: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
        currentUser: action.payload.user,
        allreadyexist: action.payload.allreadyexist,
        userNotFound: action.payload.userNotFound,
        loginSuccess: action.payload.loginSuccess,
      };
    case LOGOUT_USER:
      return {
        ...state,
        currentUser: {
          user_id: "",
          is_admin: false,
          name: "",
        },
        isLoggedIn: false,
        allreadyexist: false,
        userNotFound: false,
        loginSuccess: false,
      };
    default:
      return state;
  }
};

export default authReducer;
