import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";

class WishList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      wishlist: [],
      discount: 0,
    };

    this.getDataFromDB();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  getDataFromDB() {
    axios.get("/products/").then((response) => {
      if (response.data.length > 0) {
        this.setState({
          products: response.data,
        });
      }
    });
    axios
      .get("/settings/")
      .then((res) => {
        if (res.data === null) {
          this.setState({
            error: true,
          });
        } else {
          this.setState({
            discount: res.data.discount,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .post(
        "/users/wishList",
        {
          userID: this.props.currentUser.user_id,
        },
        {
          headers: {
            authorization: localStorage.c,
          },
        }
      )
      .then((response) => {
        if (response.data) {
          this.setState({
            wishlist: response.data,
          });
        } else {
          // this.props.history.push("/");
        }
      })
      .catch((err) => {
        console.log("Error:" + err);
        this.props.history.push("/");
      });
  }
  removeFromWishlist(itemCode) {
    axios
      .patch(
        "/users/removeFromWishlist",
        {
          userID: this.props.currentUser.user_id,
          wishItem: {
            itemCode: itemCode,
          },
        },
        {
          headers: {
            authorization: localStorage.c,
          },
        }
      )
      .then((response) => {
        var wishlist = this.state.wishlist.filter(function (jsonObject) {
          return jsonObject.itemCode !== itemCode;
        });
        this.setState({
          wishlist: wishlist,
        });
      })
      .catch((err) => {
        console.log("Error:" + err);
      });
  }
  render() {
    return (
      <div className="container-fluid wish-list page-body">
        <div className="container-fluid main-title text-uppercase">
          <div className="row">
            <div className="col-12">Wish List</div>
          </div>
          <hr />
        </div>
        <div className="">
          {this.state.products.length > 0
            ? this.state.wishlist.map((item) => {
                const product = this.state.products.find(
                  (i) => i.itemCode === item.itemCode
                );
                return (
                  <div
                    key={item._id}
                    className="card p-2 m-auto"
                    style={{ maxHeight: "", maxWidth: "75%" }}
                  >
                    <div className="row no-gutters">
                      <div className="col-sm-2 text-center m-auto">
                        <Link to={"/details/" + product.itemCode}>
                          <img
                            src={product.mainImage}
                            alt="product"
                            className="img-fluid img-thumbnail"
                            style={{ maxHeight: "100px", maxWidth: "100%" }}
                          />
                        </Link>
                      </div>
                      <div className="col-sm-3 m-auto text-center">
                        <h5>
                          {product.title} -
                          <small className="text-muted">
                            {" "}
                            {product.itemCode}
                          </small>
                        </h5>
                      </div>
                      <div className="col-sm-2 m-auto">
                        Rs:{" "}
                        {(product.price *
                          (100 -
                            (5 +
                              (product.isNoDiscount
                                ? 0
                                : this.state.discount)))) /
                          100}{" "}
                        <span
                          style={{
                            color: "#b30000",
                            fontSize: "0.8rem",
                          }}
                        >
                          {`(-${
                            5 + (product.isNoDiscount ? 0 : this.state.discount)
                          }%)`}
                        </span>
                      </div>
                      <div className="col-sm-2 m-auto">
                        <span>
                          {product.quantity < 1 ? (
                            <font color="orange">Out Of stock</font>
                          ) : (
                            <font color="#3bce34">In stock</font>
                          )}
                        </span>
                      </div>

                      <div className="col-sm-2 text-center my-auto">
                        {product.quantity > 0 ? (
                          <Link
                            to={{
                              pathname: "/placeorder",
                              data: {
                                selectedItems: [product.itemCode],
                                quantity: [
                                  {
                                    itemCode: product.itemCode,
                                    price: product.price,
                                    quantity: 1,
                                  },
                                ],
                                total: product.price,
                              },
                            }}
                          >
                            <button className="btn btn-dark m-1">
                              Buy Now
                            </button>
                          </Link>
                        ) : (
                          <button disabled className="btn btn-dark m-1">
                            Buy Now
                          </button>
                        )}
                      </div>
                      <div className="col-sm-1 text-center my-auto">
                        <button
                          onClick={() =>
                            this.removeFromWishlist(product.itemCode)
                          }
                          className="remove"
                          style={{ background: "none", border: "none" }}
                        >
                          <i className="fas fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps, {})(WishList);
