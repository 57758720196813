import React, { Component } from "react";

class ContactUs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="page-body container-fluid">
        <div className="container-fluid main-title text-uppercase">
          <div className="row">
            <div className="col-12">CONTACT US</div>
          </div>
          <hr />
        </div>
        <div className="row mx-4">
          <div className="col-12 col-sm-6 text-left mb-3">
            <p>
              ADDRESS :{" "}
              <span
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bold"
                }}
              >
                No : 51/B, Kanduboda, Delgoda
              </span>
            </p>
            <p>
              PHONE :
              <a
                href="tel:0719643899"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bold"
                }}
              >
                {" "}
                0719643899
              </a>
            </p>

            <p>
              E-MAIL :
              <a
                href="mailto:klovergroups@gmail.com"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bold"
                }}
              >
                {" "}
                klovergroups@gmail.com
              </a>
            </p>
            <div className="row">
              <div className="col-4 text-right">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://m.me/kloversaree?fbclid=IwAR2_7nbiSdFf9IsgjCUWIe9itj9mYXlQano7qvTAeymEfKhDCJ3xBTd2Vso"
                >
                  <img src="img/msngr.png" alt="Messenger klover" width="50" />
                </a>
              </div>
              <div className="col-4 text-left">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://wa.me/+94719643899"
                >
                  <img
                    src="img/whatsapp.png"
                    alt="whatsapp klover"
                    width="54"
                  />
                </a>
              </div>
            </div>

            <p style={{ marginTop: "3rem" }}>FOLLOW US ON </p>
            <div style={{ marginLeft: "3rem" }}>
              <div className="row">
                <div className="col-12">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/kloversaree/"
                  >
                    <img src="img/fac.png" width="50" alt="klover facebook" />
                    <span
                      style={{
                        // margin: "1rem 1rem 2rem 1rem",
                        color: "black",
                        fontWeight: "bold",
                        marginLeft: "1rem"
                      }}
                    >
                      {" "}
                      kloversaree
                    </span>
                  </a>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/kloversaree/"
                  >
                    <img src="img/inst.png" width="50" alt="klover instagram" />
                    <span
                      style={{
                        // margin: "1rem 1rem 2rem 1rem",
                        color: "black",
                        fontWeight: "bold",
                        marginLeft: "1rem"
                      }}
                    >
                      {" "}
                      @kloversaree
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 text-right">
            <div className=" shadow" style={{ height: "100%", width: "100%" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d371.9786324288415!2d80.01246010706268!3d6.9739417273018764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae255fa12dfced3%3A0x39a9ccb419e2a80e!2sKLOVER!5e0!3m2!1sen!2slk!4v1570354573080!5m2!1sen!2slk"
                width="100%"
                height="360"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                title="map"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;
