import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { itemUpdated } from "../../actions/addToCartAction";

class AddToCart extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseToCart = this.handleCloseToCart.bind(this);
  }
  handleClose() {
    document.getElementById("closeAndcart").click();
    this.props.itemUpdated();
  }
  handleCloseToCart() {
    document.getElementById("closeAndcart").click();
    this.props.itemUpdated();
    this.props.history.push("/cart");
  }

  render() {
    return (
      <div className="popup">
        <div
          className="modal fade"
          id="addToCartPopup"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addToCartPopupTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-center">
                  {this.props.addToCart.product.itemCode < 1
                    ? null
                    : "Item Successfully Added !"}
                </h5>

                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.handleClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {this.props.addToCart.error.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-warning mx-3" role="alert">
                      {this.props.addToCart.error}
                    </div>
                  </div>
                </div>
              ) : this.props.addToCart.product.itemCode < 1 ? (
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-primary mx-3" role="alert">
                      Uploading !
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <div className="modal-body text-center">
                    <img
                      src={this.props.addToCart.product.mainImage}
                      className="img-fluid w-50"
                      alt="product"
                    />
                    <h5>
                      <font color="#a1a5a1">
                        {this.props.addToCart.product.itemCode}
                      </font>
                    </h5>
                    <h4>Rs: {this.props.addToCart.total}</h4>
                  </div>
                </React.Fragment>
              )}
              <div className="text-center">
                <button
                  onClick={this.handleClose}
                  type="button"
                  className="btn btn-dark btn-sm m-2 w-75"
                >
                  Continue Shopping
                </button>
                <button
                  type="button"
                  className="btn btn-dark btn-sm mx-2 w-50 mb-3"
                  onClick={this.handleCloseToCart}
                >
                  Go To Cart
                </button>

                <button
                  id="closeAndcart"
                  data-dismiss="modal"
                  style={{ display: "none" }}
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  isLoggedIn: state.auth.isLoggedIn,
  addToCart: state.addToCart
});

export default connect(mapStateToProps, { itemUpdated })(withRouter(AddToCart));
