import { ADD_TO_CART, ITEM_UPDATED, ADD_TO_CART_ERROR } from "../actions/types";

const initialState = {
  product: {
    mainImage: "",
    itemCode: ""
  },
  total: 0,
  addedItem: "",
  error: ""
};

const addToCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        total: action.payload.total,
        product: action.payload.product,
        addedItem: action.payload.product.itemCode,
        error: ""
      };
    case ITEM_UPDATED:
      return {
        ...state,
        product: {
          mainImage: "",
          itemCode: ""
        },
        total: 0,
        addedItem: "",
        error: ""
      };
    case ADD_TO_CART_ERROR:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export default addToCartReducer;
