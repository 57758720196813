import { ADD_TO_CART, ITEM_UPDATED, ADD_TO_CART_ERROR } from "./types";
import axios from "axios";

export const handleAddToCart = (data) => {
  return (dispatch) => {
    // console.log(data);

    axios
      .post(
        "/users/cart",
        {
          userID: data.userID,
        },
        {
          headers: {
            authorization: localStorage.c,
          },
        }
      )
      .then((response) => {
        if (response.data) {
          const cart = response.data.filter((item) => {
            return item.itemCode === data.product.itemCode;
          });
          if (cart.length < 1) {
            axios
              .patch(
                "/users/addToCart/",
                {
                  userID: data.userID,
                  cartItem: {
                    itemCode: data.product.itemCode,
                    quantity: data.quantity,
                    price: data.product.price,
                  },
                },
                {
                  headers: {
                    authorization: localStorage.c,
                  },
                }
              )
              .then((res) => {
                dispatch({
                  type: ADD_TO_CART,
                  payload: {
                    product: data.product,
                    total: data.total,
                  },
                });
                console.log(data.product.itemCode + "Added to cart.");
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            dispatch({
              type: ADD_TO_CART_ERROR,
              payload: "Item already added to cart!",
            });
            console.log(data.product.itemCode + "Added to cart.");
          }
        } else {
          axios
            .patch(
              "/users/addToCart/",
              {
                userID: data.userID,
                cartItem: {
                  itemCode: data.product.itemCode,
                  quantity: data.quantity,
                  price: data.product.price,
                },
              },
              {
                headers: {
                  authorization: localStorage.c,
                },
              }
            )
            .then((res) => {
              dispatch({
                type: ADD_TO_CART,
                payload: {
                  product: data.product,
                  total: data.total,
                },
              });
              console.log(data.product.itemCode + "Added to cart.");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log("Error:" + err);
      });
  };
};

export const itemUpdated = () => {
  return (dispatch) => {
    dispatch({
      type: ITEM_UPDATED,
    });
  };
};
