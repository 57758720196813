import React, { Component } from "react";
class DisplayShippingInfo extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-8">
          <div className="row" style={{ fontWeight: "bold" }}>
            <div className="col-12">{this.props.address.fullName},</div>
          </div>
          <div className="row">
            <div className="col-12">{this.props.address.address},</div>
          </div>
          <div className="row">
            <div className="col-12">{this.props.address.address2},</div>
          </div>
          <div className="row">
            <div className="col-12">{this.props.address.city},</div>
          </div>
          <div className="row">
            <div className="col-12">{this.props.address.country}</div>
          </div>
          <div className="row">
            <div className="col-12" style={{ fontWeight: "bold" }}>
              {this.props.address.phoneNumber}
            </div>
          </div>
          <div className="row">
            <div className="col-12">{this.props.email}</div>
          </div>
        </div>
        <div className="col-4 m-auto">
          <div className="row">
            <div className="col-12">
              <button
                className="btn btn-dark"
                onClick={() => {
                  this.props.setEdit();
                }}
              >
                Edit
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              <button
                disabled={this.props.isAddressAddAsDefault}
                className="btn btn-dark btn-sm"
                onClick={() => {
                  this.props.setDefaultAddress();
                }}
              >
                Set As Default
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DisplayShippingInfo;
