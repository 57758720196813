import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: [],
      selectedItems: [],
      total: 0,
      discount: 0,
    };
    this.getDataFromDB();
    this.counter = this.counter.bind(this);
    this.handlecheckbox = this.handlecheckbox.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  updateTotal(selecItems = null) {
    var total = 0;
    this.state.cart.map((item) => {
      if (
        (selecItems ? selecItems : this.state.selectedItems).includes(
          item.itemCode
        )
      ) {
        if (item.isNoDiscount) {
          total += (item.price * item.quantity * (100 - 5)) / 100;
        } else {
          total +=
            (item.price * item.quantity * (100 - this.state.discount - 5)) /
            100;
        }
      }
      return null;
    });

    this.setState({ total: total });
  }
  getDataFromDB() {
    axios
      .get("/settings/")
      .then((res) => {
        if (res.data === null) {
          this.setState({
            error: true,
          });
        } else {
          this.setState({
            discount: res.data.discount,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("/products/")
      .then((response) => {
        if (response.data.length > 0) {
          var products = response.data;
          axios
            .post(
              "/users/cart",
              {
                userID: this.props.currentUser.user_id,
              },
              {
                headers: {
                  authorization: localStorage.c,
                },
              }
            )
            .then((cartresponse) => {
              if (cartresponse.data) {
                var cart = [];
                cartresponse.data.forEach((element) => {
                  var product = products.find(
                    (i) => i.itemCode === element.itemCode
                  );
                  if (product) {
                    cart.push({
                      ...element,
                      price: product.price,
                      title: product.title,
                      category: product.category,
                      mainImage: product.mainImage,
                      isNoDiscount: product.isNoDiscount,
                      avaiableQuantity: product.quantity,
                    });
                  }
                });

                this.setState({
                  cart: cart,
                });
              }
            })
            .catch((err) => {
              console.log("Error:" + err);
              this.props.history.push("/");
            });
        }
      })
      .catch((err) => {
        console.log("Error:" + err);
      });
  }

  counter(pra, itemCode) {
    const itemS = this.state.cart.find((i) => i.itemCode === itemCode);

    if (pra === -1) {
      if (itemS.quantity > 1) {
        const count = itemS.quantity + pra;
        var cart = this.state.cart;
        var item = cart.find((x) => x.itemCode === itemCode);
        if (item) {
          item.quantity = count;
        }
        this.setState({
          cart: cart,
        });
      }
    } else {
      if (itemS.quantity < itemS.avaiableQuantity) {
        const count = itemS.quantity + pra;
        var cart1 = this.state.cart;
        var item1 = cart1.find((x) => x.itemCode === itemCode);
        if (item1) {
          item1.quantity = count;
        }
        this.setState({
          cart: cart1,
        });
      }
    }
    this.updateTotal();
  }
  handleWishList(itemCode) {
    axios
      .patch(
        "/users/addToWishlist",
        {
          userID: this.props.currentUser.user_id,
          wishItem: {
            itemCode: itemCode,
          },
        },
        {
          headers: {
            authorization: localStorage.c,
          },
        }
      )
      .then((response) => {
        console.log(itemCode + " added to wishlist");
      })
      .catch((err) => {
        console.log("Error:" + err);
      });
  }
  removeFromCart(itemCode) {
    axios
      .patch(
        "/users/removeFromCart",
        {
          userID: this.props.currentUser.user_id,
          cartItem: {
            itemCode: itemCode,
          },
        },
        {
          headers: {
            authorization: localStorage.c,
          },
        }
      )
      .then((response) => {
        var cart = this.state.cart.filter(function (jsonObject) {
          return jsonObject.itemCode !== itemCode;
        });
        var selec = this.state.selectedItems.filter(function (item) {
          return item !== itemCode;
        });

        this.setState({
          cart: cart,
          selectedItems: selec,
        });
        this.updateTotal();
      })
      .catch((err) => {
        console.log("Error:" + err);
      });
  }
  handlecheckbox(itemCode, status) {
    if (status) {
      var selecItem = this.state.selectedItems;
      selecItem.push(itemCode);
      this.setState({
        selectedItems: selecItem,
      });
      this.updateTotal();
    } else {
      var selecItem1 = this.state.selectedItems.filter(function (item) {
        return item !== itemCode;
      });
      this.setState({
        selectedItems: selecItem1,
      });
      this.updateTotal(selecItem1);
    }
  }
  render() {
    return (
      <div className="container-fluid cart page-body">
        <div className="container-fluid main-title text-uppercase">
          <div className="row">
            <div className="col-12">Shopping Cart</div>
          </div>
          <hr />
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 mb-3">
              {false ? (
                <div className="row">
                  <div className="col-12 text-left">
                    <div className="form-check mb-3 mx-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        style={{ width: "1.1rem", height: "1.1rem" }}
                      />
                      <label
                        className="form-check-label mx-2"
                        for="exampleCheck1"
                      >
                        Select All
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}
              {this.state.cart.map((item) => {
                return (
                  <div
                    key={item._id}
                    className="c p-2 m-auto"
                    style={{
                      maxWidth: "100%",
                      border: "solid 1px gray",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col-sm-1 text-center my-auto">
                        <input
                          disabled={item.avaiableQuantity < item.quantity}
                          type="checkbox"
                          style={{ width: "1.1rem", height: "1.1rem" }}
                          checked={
                            this.state.selectedItems.includes(item.itemCode) &&
                            item.avaiableQuantity >= item.quantity
                          }
                          onChange={(e) =>
                            this.handlecheckbox(item.itemCode, e.target.checked)
                          }
                        />
                      </div>
                      <div className="col-sm-2 text-center">
                        <Link to={"/details/" + item.itemCode}>
                          <img
                            src={item.mainImage}
                            alt="product"
                            className="img-fluid img-thumbnail"
                            style={{ maxHeight: "100px", maxWidth: "100%" }}
                          />
                        </Link>
                      </div>
                      <div className="col-sm-3 my-auto text-center">
                        <h5 className="c-title">
                          {item.title} -
                          <small className="text-muted"> {item.itemCode}</small>
                        </h5>
                        <p className="c-text">
                          Rs:{" "}
                          {(item.price *
                            (100 -
                              (5 +
                                (item.isNoDiscount
                                  ? 0
                                  : this.state.discount)))) /
                            100}{" "}
                          <span
                            style={{
                              color: "#b30000",
                              fontSize: "0.8rem",
                            }}
                          >
                            {`(-${
                              5 + (item.isNoDiscount ? 0 : this.state.discount)
                            }%)`}
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-2 my-auto text-center">
                        <div className="row no-gutters">
                          <div className="col-4 text-right">
                            <button
                              className="count-button"
                              onClick={() => this.counter(-1, item.itemCode)}
                            >
                              -
                            </button>
                          </div>
                          <div className="col-4 text-center">
                            <input
                              className="count"
                              // id="result"
                              name="count"
                              value={item.quantity}
                              readOnly
                            />
                          </div>
                          <div className="col-4 text-left">
                            <button
                              className="count-button"
                              onClick={() => this.counter(1, item.itemCode)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2 my-auto text-center">
                        <span>
                          {item.quantity > item.avaiableQuantity ? (
                            <font color="orange">Out Of stock</font>
                          ) : (
                            <font color="#3bce34">In stock</font>
                          )}
                        </span>
                      </div>

                      <div className="col-sm-1 text-center my-auto">
                        <span
                          title="Add to wishList"
                          onClick={() =>
                            this.props.isLoggedIn
                              ? this.handleWishList(item.itemCode)
                              : document
                                  .getElementById("authpopupDetails")
                                  .click()
                          }
                          className="wish-list"
                          style={{ background: "none", border: "none" }}
                        >
                          <i
                            className="fas fa-heart"
                            style={{
                              fontSize: "1.5rem",
                              color: "gray",
                              marginLeft: "1rem",
                            }}
                          />
                        </span>
                      </div>
                      <div className="col-sm-1 text-center my-auto">
                        <button
                          onClick={() => this.removeFromCart(item.itemCode)}
                          className="remove"
                          style={{ background: "none", border: "none" }}
                        >
                          <i className="fas fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-md-3">
              <div className="sticky-top" style={{ top: "7em" }}>
                <div className="my-3">
                  <div
                    className="c p-3"
                    style={{
                      maxWidth: "100%",
                      height: "100%",
                      border: "solid 1px gray",
                      borderRadius: "5px",
                    }}
                  >
                    <h6>Order Summery</h6>
                    <hr className="mt-0 mb-2 mx-2" />
                    <div className="body px-2">
                      {this.state.cart.map((item, index) => {
                        return this.state.selectedItems.includes(
                          item.itemCode
                        ) ? (
                          <div className="row" key={index}>
                            <div className="col-5">
                              <small className="mr-3">{item.itemCode} : </small>
                            </div>
                            <div className="col-7 text-left">
                              <small>
                                Rs{" "}
                                {(item.price *
                                  (100 -
                                    (item.isNoDiscount
                                      ? 5
                                      : 5 + this.state.discount))) /
                                  100}{" "}
                                x {item.quantity}
                              </small>
                            </div>
                          </div>
                        ) : null;
                      })}

                      <hr className="m-2" />
                      <h6 className="my-2">Total : Rs {this.state.total}</h6>
                      <div className="text-center">
                        <Link
                          to={{
                            pathname: "/placeorder",
                            data: {
                              selectedItems: this.state.selectedItems,
                              quantity: this.state.cart.filter((jsonObject) => {
                                return this.state.selectedItems.includes(
                                  jsonObject.itemCode
                                );
                              }),
                              total: this.state.total,
                            },
                          }}
                        >
                          <button
                            className="btn btn-dark"
                            disabled={
                              this.state.selectedItems.length > 0 ? false : true
                            }
                          >
                            Checkout
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps, {})(Cart);
