import React, { Component } from "react";
import Product from "./common/Product";
import axios from "axios";

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      keyword: "",
      discount: 0,
      discountImage: "",
    };
  }
  componentDidUpdate() {
    if (this.state.keyword !== this.props.match.params.id) {
      this.getDataFromDB();

      axios
        .get("/settings/")
        .then((res) => {
          if (res.data === null) {
            this.setState({
              error: true,
            });
          } else {
            // console.log(res);
            this.setState({
              discount: res.data.discount,
              discountImage: res.data.discountImage,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  getDataFromDB() {
    var data = this.props.match.params.id.toLowerCase().split("+");
    var new_data = "";
    data.forEach((element) => {
      if (element.search("saree") === -1) {
        new_data += element + "+";
      }
    });
    axios.post("/products/search", { keywords: new_data }).then((response) => {
      if (response.data.length > 0) {
        this.setState({
          products: response.data,
          keyword: this.props.match.params.id,
        });
      } else {
        this.setState({
          products: [],
          keyword: this.props.match.params.id,
        });
      }
    });
  }
  componentDidMount() {
    this.getDataFromDB();
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page-body productList">
        <div className="container-fluid ">
          <div className="container-fluid text-center font-weight-bold pt-3">
            <div className="row">
              <div className="col-12">
                Search Results for "{this.state.keyword}"
              </div>
            </div>
            <hr />
          </div>
          <div className="row no-gutters">
            {this.state.products.map((product, index) => {
              return (
                <div
                  key={index}
                  className="col-6 col-md-4 col-lg-3 my-3 px-md-3 px-1 mx-auto "
                >
                  <Product
                    product={product}
                    discount={this.state.discount}
                    discountImage={this.state.discountImage}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Search;
