import React, { Component } from "react";
import "../../css/SlideshowProduct.css";
import Product from "../common/Product";

class SlideshowProduct extends Component {
  render() {
    const { id, products, discount, discountImage } = this.props;
    return (
      <div className="container-fluid products-new">
        <div id={id} className="carousel slide m-2" data-ride="carousel">
          <a
            className="carousel-control-prev"
            href={"#" + id}
            role="button"
            data-slide="prev"
          >
            <span className="control-icon" aria-hidden="true">
              <i className="fas fa-chevron-left"></i>
            </span>
          </a>
          <div
            className="carousel-inner w-100 mx-auto no-gutters"
            role="listbox"
          >
            {products.map((product, index) => {
              if (index > 0 && product.quantity > 0) {
                return (
                  <div
                    key={index}
                    className="carousel-item-sp carousel-item col-6 col-md-4 col-lg-3 px-md-3 px-1"
                  >
                    <Product
                      product={product}
                      discount={discount}
                      discountImage={discountImage}
                    />
                  </div>
                );
              } else if (product.quantity > 0) {
                return (
                  <div
                    key={index}
                    className="carousel-item-sp carousel-item col-6 col-md-4 col-lg-3 px-md-3 px-1 active"
                  >
                    <Product
                      product={product}
                      discount={discount}
                      discountImage={discountImage}
                    />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          <a
            className="carousel-control-next "
            href={"#" + id}
            role="button"
            data-slide="next"
          >
            <span className="control-icon" aria-hidden="true">
              <i className="fas fa-chevron-right"></i>
            </span>
          </a>
        </div>
      </div>
    );
  }
}

export default SlideshowProduct;
