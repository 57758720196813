import React, { Component } from "react";
import axios from "axios";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [0, 1, 2, 3, 4, 5],
      data: [],
      orderStatus: "All",
      orderID: "",
      isInvalidOrderID: false,
      wayBillNumber: "",
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.getData();
  }
  getData() {
    axios
      .get("/orders/", {
        headers: {
          authorization: localStorage.c,
        },
      })
      .then((res) => {
        if (res.data === null) {
          this.setState({
            error: true,
          });
        } else {
          this.setState({
            data: res.data,
            error: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  updateStatus(index, orderID, status, items, oldStatus, email) {
    document.getElementById("orderStatusUp" + index).style.display = "none";
    document.getElementById("orderStatus" + index).style.display = "block";
    if (status !== null && status !== "") {
      axios
        .patch(
          "/orders/updateStatus/",
          {
            orderID: orderID,
            orderStatus: status,
            oldStatus: oldStatus,
            email: email,
          },
          {
            headers: {
              authorization: localStorage.c,
            },
          }
        )
        .then((res) => {
          if (res.data === null) {
            this.setState({
              error: true,
            });
          } else {
            axios
              .patch("/products/updateStatus/", {
                items: items,
                orderStatus: status,
              })
              .then((res) => {
                var data = this.state.data;
                data[index].orderStatus = status;
                this.setState({ data: data, error: true });
              })
              .catch((err) => {
                console.log("Error: " + err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  updateWaybill(index, orderID, waybill) {
    if (waybill !== null && waybill !== "") {
      axios
        .patch(
          "/orders/updateWaybillnumber/",
          {
            orderID: orderID,
            wayBillNumber: waybill,
          },
          {
            headers: {
              authorization: localStorage.c,
            },
          }
        )
        .then((res) => {
          if (res.data === null) {
            this.setState({
              error: true,
            });
          } else {
            var data = this.state.data;
            data[index].wayBillNumber = waybill;
            this.setState({ data: data, error: true });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  handleSearch(event) {
    event.preventDefault();
    if (this.state.orderID.length !== 0) {
      axios
        .get("/orders/" + this.state.orderID)
        .then((res) => {
          if (res.data === null) {
            this.setState({
              error: true,
            });
          } else {
            this.setState({
              data: [res.data],
              error: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (this.state.orderStatus !== "All") {
      axios
        .get("/orders/status/" + this.state.orderStatus, {
          headers: {
            authorization: localStorage.c,
          },
        })
        .then((res) => {
          if (res.data === null) {
            this.setState({
              error: true,
            });
          } else {
            this.setState({
              data: res.data,
              error: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get("/orders/", {
          headers: {
            authorization: localStorage.c,
          },
        })
        .then((res) => {
          if (res.data === null) {
            this.setState({
              error: true,
            });
          } else {
            this.setState({
              data: res.data,
              error: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  render() {
    return (
      <div className="page-body-admin container-fluid">
        <div className="container-fluid main-title text-uppercase">
          <div className="row">
            <div className="col-12">Manage Orders</div>
          </div>
          <hr />
        </div>
        <form onSubmit={this.handleSearch}>
          {this.state.isInvalidOrderID ? (
            <div className="alert alert-danger fade show" role="alert">
              <strong>Order Not Found!</strong> Invalid Order Id.
            </div>
          ) : null}
          <div
            className="row p-2 m-2"
            style={{ border: "1px solid gray", borderRadius: "10px" }}
          >
            <div className="col-4 offset-sm-1 px-4 text-center">
              <div className=" m-auto">
                <label htmlFor="orderStatus">Order Status</label>
                <select
                  className="form-control"
                  id="orderStatus"
                  value={this.state.orderStatus}
                  disabled={this.state.orderID.length === 0 ? false : true}
                  onChange={(e) =>
                    this.setState({ orderStatus: e.target.value })
                  }
                >
                  <option value="All">All</option>
                  <option value="Pending">Pending</option>
                  <option value="Processing">Processing</option>
                  <option value="On Delivery">On Delivery</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
            </div>
            <div className="col-4 px-4 text-center">
              <div className="">
                <label htmlFor="searchInput">Order ID</label>
                <input
                  type="text"
                  value={this.state.orderID}
                  onChange={(e) => this.setState({ orderID: e.target.value })}
                  className="form-control"
                  id="searchInput"
                  placeholder="Order ID"
                  disabled={this.state.orderStatus !== "All" ? true : false}
                />
              </div>
            </div>
            <div className="col-3 px-4 text-center m-auto">
              <button type="submit" className="btn btn-dark m-auto">
                Search
              </button>
            </div>
          </div>
        </form>
        <hr
          style={{
            height: "1px",
            backgroundColor: "gray",
            marginRight: "10%",
            marginLeft: "10%",
          }}
        />
        <div
          className="grid"
          style={{
            overflowX: "scroll",
            padding: "0px 15px 15px 15px",
          }}
        >
          <div
            className="row text-center"
            style={{
              border: "1px solid black",
              borderRadius: "10px",
              overflow: "hidden",
              fontSize: "0.9rem",
              minWidth: "1040px",
            }}
          >
            <div className="col-12">
              <div
                className="row"
                style={{
                  borderBottom: "1px solid black",
                  backgroundColor: "gray",
                  color: "white",
                }}
              >
                <div
                  className="col-2 p-2"
                  style={{ borderRight: "1px solid black" }}
                >
                  Date
                </div>
                <div
                  className="col-1 p-2"
                  style={{ borderRight: "1px solid black" }}
                >
                  Order ID
                </div>
                <div
                  className="col-1 p-2"
                  style={{ borderRight: "1px solid black" }}
                >
                  User ID
                </div>
                <div
                  className="col-1 p-2"
                  style={{ borderRight: "1px solid black" }}
                >
                  Item Code
                </div>
                <div
                  className="col-1 p-2"
                  style={{ borderRight: "1px solid black" }}
                >
                  Quantity
                </div>
                <div
                  className="col-1 py-2 px-0"
                  style={{ borderRight: "1px solid black" }}
                >
                  Order Type
                </div>
                <div
                  className="col-1 p-2"
                  style={{ borderRight: "1px solid black" }}
                >
                  Payment
                </div>
                <div
                  className="col-3 p-2"
                  style={{ borderRight: "1px solid black" }}
                >
                  Order Status
                </div>
              </div>
              {this.state.data.map((order, index) => {
                var status = null;
                var wayBillNumber = null;
                return (
                  <React.Fragment key={index}>
                    <div
                      className="row"
                      style={{
                        borderBottom: "1px solid gray",
                        borderTop: "1.5px solid black",
                      }}
                    >
                      <div
                        className="col-2 p-2"
                        style={{ borderRight: "1px solid black" }}
                      >
                        {new Date(order.date).toLocaleString("en-US", {
                          timeZone: "Asia/Kolkata",
                        })}
                      </div>
                      <div
                        className="col-1 p-2"
                        style={{ borderRight: "1px solid black" }}
                      >
                        {order.orderID}
                      </div>
                      <div
                        className="col-1 p-2"
                        style={{ borderRight: "1px solid black" }}
                      >
                        {order.userID}
                      </div>
                      <div className="col-2">
                        {order.items.map((item, i) => {
                          return (
                            <div key={index + "c" + i} className="row">
                              <div
                                className="col-6 p-2"
                                style={
                                  i !== 0
                                    ? {
                                        borderRight: "1px solid black",
                                        borderTop: "1px solid black",
                                      }
                                    : {
                                        borderRight: "1px solid black",
                                      }
                                }
                              >
                                {item.itemCode}
                              </div>
                              <div
                                className="col-6 p-2"
                                style={
                                  i !== 0
                                    ? {
                                        borderRight: "1px solid black",
                                        borderTop: "1px solid black",
                                      }
                                    : {
                                        borderRight: "1px solid black",
                                      }
                                }
                              >
                                {item.quantity}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        className="col-1 p-2"
                        style={{ borderRight: "1px solid black" }}
                      >
                        {order.paymentMethod}
                      </div>
                      <div
                        className="col-1 p-2"
                        style={{ borderRight: "1px solid black" }}
                      >
                        Rs: {order.Payment}
                      </div>
                      <div
                        className="col-3 p-2"
                        style={{ borderRight: "1px solid black" }}
                      >
                        <div id={"orderStatus" + index}>
                          {order.orderStatus}
                          <button
                            style={{
                              background: "none",
                              border: "none",
                            }}
                            onClick={() => {
                              document.getElementById(
                                "orderStatusUp" + index
                              ).style.display = "block";
                              document.getElementById(
                                "orderStatus" + index
                              ).style.display = "none";
                            }}
                          >
                            <i className="fas fa-pen mx-2" />
                          </button>
                        </div>
                        <div
                          id={"orderStatusUp" + index}
                          className=" m-auto"
                          style={{ display: "none" }}
                        >
                          <select
                            id={"orderStatus" + index}
                            onChange={(e) => (status = e.target.value)}
                          >
                            <option></option>
                            <option value="Pending">Pending</option>
                            <option value="Processing">Processing</option>
                            <option value="On Delivery">On Delivery</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Completed">Completed</option>
                          </select>
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "1.1rem",
                            }}
                            onClick={() =>
                              this.updateStatus(
                                index,
                                order.orderID,
                                status,
                                order.items,
                                order.orderStatus,
                                order.email
                              )
                            }
                          >
                            <i className="fas fa-check mx-2 text-success" />
                          </button>
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "1.1rem",
                            }}
                            onClick={() => {
                              document.getElementById(
                                "orderStatusUp" + index
                              ).style.display = "none";
                              document.getElementById(
                                "orderStatus" + index
                              ).style.display = "block";
                            }}
                          >
                            <i className="fas fa-times mx-2 text-danger" />
                          </button>
                        </div>
                        <div
                          className="m-auto"
                          style={
                            order.orderStatus === "On Delivery"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <input
                            placeholder=" waybill number"
                            type="text"
                            id={"orderWaybill" + index}
                            onChange={(e) => (wayBillNumber = e.target.value)}
                          />

                          <button
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "1.1rem",
                            }}
                            onClick={() =>
                              this.updateWaybill(
                                index,
                                order.orderID,
                                wayBillNumber
                              )
                            }
                          >
                            <i className="fas fa-check mx-2 text-success"></i>
                          </button>
                        </div>
                      </div>
                      <div className="col-1 p-2">
                        <button
                          className="remove"
                          data-toggle="collapse"
                          data-target={"#order" + index}
                          aria-expanded="false"
                          aria-controls={"order" + index}
                          style={{ background: "none", border: "none" }}
                        >
                          <i className="fas fa-chevron-down"></i>
                        </button>
                      </div>
                    </div>
                    <div
                      className="row py-2 text-left collapse"
                      id={"order" + index}
                      style={{
                        borderBottom: "1px solid gray",
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                      }}
                    >
                      <div className="col-5">
                        <div className="row">
                          <div className="col-12">
                            <strong>Name :</strong> {order.fullName}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <strong>Address :</strong>
                            <span>
                              {" "}
                              {order.address}, {order.address2}, {order.city},{" "}
                              {order.country}.
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-4">
                        <div className="row">
                          <div className="col-12">
                            <strong>Tel :</strong> {order.phoneNumber}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <strong>Email :</strong> {order.email}
                          </div>
                        </div>
                      </div>

                      <div className="col-3">
                        <div className="row">
                          <div className="col-12">
                            <strong>Promo Code :</strong> {order.promoCode}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <strong>Waybill number :</strong>{" "}
                            {order.wayBillNumber}
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Orders;
