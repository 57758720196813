import { LOGIN_USER, LOGOUT_USER } from "./types";
import axios from "axios";
export const handleSignup = (user) => {
  return (dispatch) => {
    axios
      .post("/users/signUp", {
        fullName: user.fullName,
        password: user.password,
        email: user.email,
      })
      .then((res) => {
        if (res.data.allreadyexist) {
          localStorage.removeItem("c");
          dispatch(
            loginUser({
              user: {},
              allreadyexist: true,
              userNotFound: false,
              loginSuccess: false,
              isLoggedIn: false,
            })
          );
        } else {
          localStorage.setItem("c", res.data.authToken);
          dispatch(
            loginUser({
              user: res.data.user,
              allreadyexist: false,
              userNotFound: false,
              loginSuccess: true,
              isLoggedIn: true,
            })
          );
        }
      })
      .catch((err) => {
        localStorage.removeItem("c");
        dispatch(
          loginUser({
            user: {},
            allreadyexist: false,
            userNotFound: true,
            loginSuccess: false,
            isLoggedIn: false,
          })
        );
        console.log(err);
      });
  };
};

export const handleLogin = (user) => {
  return (dispatch) => {
    axios
      .post("/users/login", {
        password: user.password,
        email: user.email,
      })
      .then((res) => {
        if (res.data.userNotFound) {
          localStorage.removeItem("c");
          dispatch(
            loginUser({
              user: {},
              allreadyexist: false,
              userNotFound: true,
              loginSuccess: false,
              isLoggedIn: false,
            })
          );
        } else {
          localStorage.setItem("c", res.data.authToken);
          dispatch(
            loginUser({
              user: res.data.user,
              allreadyexist: false,
              userNotFound: false,
              loginSuccess: true,
              isLoggedIn: true,
            })
          );
        }
      })
      .catch((err) => {
        localStorage.removeItem("c");
        dispatch(
          loginUser({
            user: {},
            allreadyexist: false,
            userNotFound: true,
            loginSuccess: false,
            isLoggedIn: false,
          })
        );
        console.log(err);
      });
  };
};

export const getProfileFetch = () => {
  return (dispatch) => {
    const token = localStorage.c;
    if (token) {
      axios
        .post(
          "/users/validate",
          {},
          {
            headers: {
              authorization: token,
            },
          }
        )
        .then((res) => {
          // console.log(res.data.user);
          dispatch(
            loginUser({
              user: res.data.user,
              allreadyexist: false,
              userNotFound: false,
              loginSuccess: true,
              isLoggedIn: true,
            })
          );
        })
        .catch((err) => {
          localStorage.removeItem("c");
          dispatch(
            loginUser({
              user: {},
              allreadyexist: false,
              userNotFound: false,
              loginSuccess: false,
              isLoggedIn: false,
            })
          );
          console.log(err);
        });
    } else {
      dispatch(
        loginUser({
          user: {},
          allreadyexist: false,
          userNotFound: false,
          loginSuccess: false,
          isLoggedIn: false,
        })
      );
    }
  };
};

export const logoutUser = () => {
  return (dispatch) => {
    localStorage.removeItem("c");
    dispatch({
      type: LOGOUT_USER,
    });
  };
};

const loginUser = (userObj) => ({
  type: LOGIN_USER,
  payload: userObj,
});
