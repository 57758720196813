import React, { Component } from "react";

import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/css/react-flags-select.css";

class EditShippingInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.address.fullName,
      phoneNumber: this.props.address.phoneNumber,
      email: this.props.email,
      address1: this.props.address.address,
      address2: this.props.address.address2,
      city: this.props.address.city,
      country: "Sri Lanka",
    };
    this.saveData = this.saveData.bind(this);
  }

  saveData(e) {
    e.preventDefault();
    var address = {
      fullName: this.state.name,
      phoneNumber: this.state.phoneNumber,
      address: this.state.address1,
      address2: this.state.address2,
      city: this.state.city,
      country: this.state.country,
    };
    this.props.setAddress(address, this.state.email);
    this.props.setEdit();
  }
  render() {
    return (
      <div className="container">
        <form onSubmit={this.saveData}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputEmail4">Full Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Name"
                value={
                  this.props.address.fullName &&
                  this.props.address.fullName.length > 0 &&
                  this.state.name.length === 0
                    ? this.props.address.fullName
                    : this.state.name
                }
                required
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputPassword4">Phone Number</label>
              <input
                className="form-control"
                id="phone"
                placeholder="Phone Number"
                value={
                  this.props.address.phoneNumber &&
                  this.props.address.phoneNumber.length > 0 &&
                  this.state.name.length === 0
                    ? this.props.address.phoneNumber
                    : this.state.phoneNumber
                }
                required
                onChange={(e) => this.setState({ phoneNumber: e.target.value })}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Email"
              value={
                this.props.email === undefined || this.state.name === undefined
                  ? this.state.email
                  : this.props.email.length > 0 && this.state.name.length === 0
                  ? this.props.email
                  : this.state.email
              }
              required
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="address1">Address</label>
              <input
                type="text"
                className="form-control"
                id="address1"
                placeholder="line 1"
                value={
                  this.props.address.address &&
                  this.props.address.address.length > 0 &&
                  this.state.name.length === 0
                    ? this.props.address.address
                    : this.state.address1
                }
                required
                onChange={(e) => this.setState({ address1: e.target.value })}
              />
            </div>
            <div className="form-group col-md-6" style={{ display: "flex" }}>
              <input
                style={{ alignSelf: "flex-end" }}
                type="text"
                className="form-control"
                id="address2"
                placeholder="line 2"
                value={
                  this.props.address.address2 &&
                  this.props.address.address2.length > 0 &&
                  this.state.name.length === 0
                    ? this.props.address.address2
                    : this.state.address2
                }
                onChange={(e) => this.setState({ address2: e.target.value })}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="city">City</label>
              <input
                type="text"
                className="form-control"
                id="city"
                value={
                  this.props.address.city &&
                  this.props.address.city.length > 0 &&
                  this.state.name.length === 0
                    ? this.props.address.city
                    : this.state.city
                }
                required
                onChange={(e) => this.setState({ city: e.target.value })}
              />
            </div>
            <div className=" col-md-6">
              <label htmlFor="country">Country</label>
              <div className="form-control m-auto" id="country">
                <ReactFlagsSelect
                  className="m-auto"
                  searchable={true}
                  defaultCountry="LK"
                  countries={["LK"]}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <button type="submit" className="btn btn-dark m-2 ">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
export default EditShippingInfo;
