import React, { Component } from "react";
import axios from "axios";
import UploadingPopUp from "../UploadingPopUp";

class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pic1: null,
      pic2: null,
      pic3: null,
      itemCode: "",
      addToNewArrival: false,
      addToNewArrivalSlideshow: false,
      addToSareeSlideshow: false,
      title: "",
      quantity: "",
      price: "",
      description: "",
      isuploadFailed: true,
      uploading: false,
      category: "office",
      isNoDiscount: false,
    };
    this.input = this.input.bind(this);
    this.uploadPic1 = this.uploadPic1.bind(this);
    this.uploadPic2 = this.uploadPic2.bind(this);
    this.uploadPic3 = this.uploadPic3.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  input(id) {
    document.getElementById(id).click();
  }
  uploadPic1 = (event) => {
    if (event.target.files[0].size > 102400) {
      alert("Image is too big! Max file size is 100KB.");
    } else {
      this.setState({ pic1: event.target.files[0] });
      var output = document.getElementById("pic1");
      output.src = URL.createObjectURL(event.target.files[0]);
      output.style.display = "block";
    }
  };
  uploadPic2 = (event) => {
    if (event.target.files[0].size > 819200) {
      alert("Image is too big! Max file size is 800KB.");
    } else {
      this.setState({ pic2: event.target.files[0] });
      var output = document.getElementById("pic2");
      output.src = URL.createObjectURL(event.target.files[0]);
      output.style.display = "block";
    }
  };
  uploadPic3 = (event) => {
    if (event.target.files[0].size > 819200) {
      alert("Image is too big! Max file size is 800KB.");
    } else {
      this.setState({ pic3: event.target.files[0] });
      var output = document.getElementById("pic3");
      output.src = URL.createObjectURL(event.target.files[0]);
      output.style.display = "block";
    }
  };

  submitHandler(event) {
    document.getElementById("uploadingPopup").click();
    this.setState({ uploading: true });
    event.preventDefault();
    const data = new FormData();
    data.append("pic1", this.state.pic1);
    data.append("pic2", this.state.pic2);
    data.append("pic3", this.state.pic3);
    data.append("itemCode", this.state.itemCode);
    data.append("title", this.state.title);
    data.append("description", this.state.description);
    data.append("addToNewArrival", this.state.addToNewArrival);
    data.append(
      "addToNewArrivalSlideshow",
      this.state.addToNewArrivalSlideshow
    );
    data.append("addToSareeSlideshow", this.state.addToSareeSlideshow);
    data.append("quantity", this.state.quantity);
    data.append("price", this.state.price);
    data.append("category", this.state.category);
    data.append("isNoDiscount", this.state.isNoDiscount);
    axios
      .post("/products/addNew", data, {
        headers: {
          authorization: localStorage.c,
        },
      })
      .then((res) => {
        console.log(res.statusText);
        this.setState({
          isuploadFailed: false,
          uploading: false,
          pic1: null,
          pic2: null,
          pic3: null,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isuploadFailed: true, uploading: false });
      });
  }
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid add-product page-body-admin">
          <div className="container-fluid main-title text-uppercase">
            <div className="row">
              <div className="col-12">Add Product</div>
            </div>
            <hr />
          </div>
          <div className="row">
            <div className="col-12">
              <form onSubmit={this.submitHandler}>
                <div className="form-row">
                  <div className="form-group col-md-3">
                    <label htmlFor="inputTitle">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputTitle"
                      placeholder="Title"
                      value={this.state.title}
                      onChange={(e) => this.setState({ title: e.target.value })}
                      required
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="impotItemCode">Item Code</label>
                    <input
                      type="text"
                      className="form-control"
                      id="impotItemCode"
                      placeholder="Item Code"
                      value={this.state.itemCode}
                      onChange={(e) =>
                        this.setState({
                          itemCode: e.target.value.trim().toUpperCase(),
                        })
                      }
                      required
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="inputQuantity">Quantity</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputQuantity"
                      placeholder="Quantity"
                      value={this.state.quantity}
                      onChange={(e) =>
                        this.setState({ quantity: e.target.value.trim() })
                      }
                      required
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="inputQuantity">Price</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputPrice"
                      placeholder="Price"
                      value={this.state.price}
                      onChange={(e) =>
                        this.setState({ price: e.target.value.trim() })
                      }
                      required
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-sm-5">
                    <label htmlFor="inputDescription">Description</label>
                    <textarea
                      className="form-control"
                      id="inputDescription"
                      rows="5"
                      value={this.state.description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      required
                    ></textarea>
                  </div>

                  <div className="form-group col-sm-2 mx-2">
                    <label htmlFor="inputName">Main Image</label>
                    <div id="inputName" className="mx-2">
                      <div className="card w-100">
                        <div className="card-header text-center">
                          <span
                            className="btn btn-dark cursor-pointer"
                            onClick={() => this.input("input_btn1")}
                          >
                            +
                            <input
                              id="input_btn1"
                              className="btn btn-dark input_btn"
                              type="file"
                              onChange={this.uploadPic1}
                              required
                            />
                          </span>
                        </div>
                        <div className="card-body">
                          <img id="pic1" className="" src="" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-sm-2 mx-2">
                    <label htmlFor="inputName">Image 2</label>
                    <div id="inputName" className="mx-2">
                      <div className="card w-100">
                        <div className="card-header text-center">
                          <span
                            className="btn btn-dark cursor-pointer"
                            onClick={() => this.input("input_btn2")}
                          >
                            +
                            <input
                              id="input_btn2"
                              className="btn btn-dark input_btn"
                              type="file"
                              onChange={this.uploadPic2}
                            />
                          </span>
                        </div>
                        <div className="card-body">
                          <img id="pic2" className="" src="" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-sm-2 mx-2">
                    <label htmlFor="inputName">Image 3</label>
                    <div id="inputName" className="mx-2">
                      <div className="card w-100">
                        <div className="card-header text-center">
                          <span
                            className="btn btn-dark cursor-pointer"
                            onClick={() => this.input("input_btn3")}
                          >
                            +
                            <input
                              id="input_btn3"
                              className="btn btn-dark input_btn"
                              type="file"
                              onChange={this.uploadPic3}
                            />
                          </span>
                        </div>
                        <div className="card-body">
                          <img id="pic3" className="" src="" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row mx-auto">
                  <div className="form-group form-check col-sm-3 col-md-3 pr-5">
                    <label htmlFor="category">Category</label>
                    <select
                      className="form-control"
                      id="category"
                      onChange={(e) =>
                        this.setState({ category: e.target.value })
                      }
                      defaultValue="office"
                    >
                      <option value="designer">Designer Wear</option>
                      <option value="party">Party Wear</option>
                      <option value="office">Office Wear</option>
                      <option value="bridal">Bridal Wear</option>
                    </select>
                  </div>
                  <div className="form-group form-check col-sm-3 col-md-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkboxDis"
                      onChange={(e) =>
                        this.setState({ isNoDiscount: e.target.checked })
                      }
                    />
                    <label className="form-check-label" htmlFor="checkboxDis">
                      No Discount
                    </label>
                  </div>{" "}
                  <div className="form-group form-check col-sm-3 col-md-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkbox1"
                      onChange={(e) =>
                        this.setState({ addToNewArrival: e.target.checked })
                      }
                    />
                    <label className="form-check-label" htmlFor="checkbox1">
                      Add To New Arrivals
                    </label>
                  </div>
                  <div className="form-group form-check col-sm-3 col-md-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkbox3"
                      onChange={(e) =>
                        this.setState({
                          addToNewArrivalSlideshow: e.target.checked,
                        })
                      }
                    />
                    <label className="form-check-label" htmlFor="checkbox3">
                      Add To New Arrivals Slideshow
                    </label>
                  </div>
                </div>
                <hr />
                <div className="form-row text-center mx-auto">
                  <button type="submit" className="btn btn-dark mx-auto">
                    Submit
                  </button>
                </div>
              </form>
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#uploadingMessage"
                id="uploadingPopup"
                style={{ display: "none" }}
              ></button>
            </div>
          </div>
        </div>
        <UploadingPopUp
          isuploadFailed={this.state.isuploadFailed}
          uploading={this.state.uploading}
        />
      </React.Fragment>
    );
  }
}

export default AddProduct;
