import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class PlaceOrderPopUp extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    document.getElementById("closeplaceorder").click();
    this.props.handlePopupclose();
    if (this.props.orderSuccessfullyPlaced) {
      this.props.history.push("/");
    }
  }
  render() {
    return (
      <div className="popup">
        <div
          className="modal fade"
          id="placeOrderPopup"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="placeOrderPopupTitle"
          aria-hidden="true"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-center">
                  {this.props.uploading
                    ? null
                    : this.props.orderSuccessfullyPlaced
                    ? "Order Successfully Submited !"
                    : "Failed !"}
                </h5>

                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.handleClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {this.props.uploading ? (
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-primary mx-3" role="alert">
                      Processing..
                    </div>
                  </div>
                </div>
              ) : !this.props.orderSuccessfullyPlaced ? (
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-danger mx-3" role="alert">
                      Failed to create the order. Please Try Again!
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <div className="modal-body text-center">
                    <strong> Your order Id : {this.props.orderID}</strong>
                    <br />
                    <br />
                    {this.props.orderType === "COD" ? (
                      <p className="mx-2">
                        We will contact you to confirm the order. <br /> If you
                        have any question please contact us.
                        <br /> Customer care : +94719643899
                      </p>
                    ) : this.props.orderType === "Bank" ? (
                      <p className="mx-2">
                        Please send a confirmation detail of your payment with
                        your order ID to
                        <strong> orders@klover.lk</strong> within 3 days.
                        <br /> If you have any question please contact us.
                        <br /> Customer care : +94719643899
                      </p>
                    ) : null}
                  </div>
                </React.Fragment>
              )}
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-dark btn-sm mx-2 w-50 mb-3"
                  onClick={this.handleClose}
                >
                  Done
                </button>

                <button
                  id="closeplaceorder"
                  data-dismiss="modal"
                  style={{ display: "none" }}
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PlaceOrderPopUp);
