import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import "../css/Details.css";
import { Link } from "react-router-dom";
import { handleAddToCart } from "../actions/addToCartAction";
import axios from "axios";
import { connect } from "react-redux";

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // cart: [],
      count: 1,
      total: 0,
      product: [],
      // isAddedToCart: false,
      isInWishlist: false,

      discount: 0,
      discountImage: "",
    };
    if (this.props.match.params.id) {
      this.getDataFromDB(this.props.match.params.id);
    } else {
      this.props.history.push("/");
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  getDataFromDB(itemCode) {
    axios
      .get("/products/" + itemCode)
      .then((response) => {
        if (response.data) {
          this.setState({
            product: response.data,
            total: response.data.price,
          });
          if (response.data.isNoDiscount) {
            this.setState({
              discount: 0,
            });
          }
        } else {
          this.props.history.push("/");
        }
      })
      .catch((err) => {
        console.log("Error:" + err);
        this.props.history.push("/");
      });

    axios
      .get("/settings/")
      .then((res) => {
        if (res.data === null) {
          this.setState({
            error: true,
          });
        } else if (this.state.product.isNoDiscount) {
          this.setState({
            discount: 0,
          });
        } else {
          this.setState({
            discount: res.data.discount,
            discountImage: res.data.discountImage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleWishList(itemCode) {
    axios
      .patch(
        "/users/addToWishlist",
        {
          userID: this.props.currentUser.user_id,
          wishItem: {
            itemCode: itemCode,
          },
        },
        {
          headers: {
            authorization: localStorage.c,
          },
        }
      )
      .then((response) => {
        console.log(itemCode + " added to wishlist");
      })
      .catch((err) => {
        console.log("Error:" + err);
      });
  }

  counter(pra) {
    if (pra === -1) {
      if (this.state.count > 1) {
        const count = this.state.count + pra;
        this.setState({
          count: count,
          total: this.state.product.price * count,
        });
      }
    } else {
      if (this.state.count < this.state.product.quantity) {
        const count = this.state.count + pra;
        this.setState({
          count: count,
          total: this.state.product.price * count,
        });
      }
    }
  }
  handleCart = () => {
    // if (!this.state.cart.includes(this.state.product.itemCode)) {
    // this.setState({ isAddedToCart: true });
    this.props.handleAddToCart({
      userID: this.props.currentUser.user_id,
      product: this.state.product,
      quantity: this.state.count,
      total: this.state.total,
    });
    document.getElementById("popupcart").click();
    // } else {
    //   console.log("already in cart");

    //   this.setState({
    //     isMessageAvailble: true,
    //     message: "Item already added to cart!"
    //   });
    // }
  };
  render() {
    return (
      <div className="details container py-3">
        <div className="row">
          <div className="col-12 mx-auto col-md-6 my-auto py-4">
            <div
              id="productSlideshow"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                {
                  //   <div className="carousel-item ">
                  //   <img
                  //     className="d-block img-fluid w-100"
                  //     src={this.state.product.mainImage}
                  //     alt="First slide"
                  //   />
                  // </div>
                }

                <div className="carousel-item active">
                  <img
                    className="d-block img-fluid w-100"
                    src={this.state.product.image2}
                    alt={`${this.state.product.title}-${this.state.product.itemCode}-1`}
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block img-fluid w-100"
                    src={this.state.product.image3}
                    alt={`${this.state.product.title}-${this.state.product.itemCode}-2`}
                  />
                </div>

                {!this.state.product.isNoDiscount && this.state.discount > 0 && (
                  <div style={{ position: "absolute" }}>
                    <img
                      src={this.state.discountImage}
                      alt="promo"
                      style={{ height: "6rem" }}
                    />
                  </div>
                )}
              </div>
              <a
                className="carousel-control-prev"
                href="#productSlideshow"
                role="button"
                data-slide="prev"
                style={{ color: "black", fontSize: "2rem" }}
              >
                <i className="fas fa-chevron-left"></i>
              </a>
              <a
                className="carousel-control-next"
                href="#productSlideshow"
                role="button"
                data-slide="next"
                style={{ color: "black", fontSize: "2rem" }}
              >
                <i className="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>

          <div className="col-12 mx-auto col-md-6 my-1 ml-5 pl-5 text-capitalize">
            <div className="row">
              <div className="col-12">
                <hr />
                <span style={{ fontWeight: "bold", fontSize: "1.7rem" }}>
                  {this.state.product.title}
                </span>
                <button
                  title="Add to wishList"
                  onClick={() =>
                    this.props.isLoggedIn
                      ? this.handleWishList(this.state.product.itemCode)
                      : document.getElementById("authpopupDetails").click()
                  }
                  className="remove"
                  style={{ background: "none", border: "none" }}
                >
                  <i
                    className="fas fa-heart"
                    style={{
                      fontSize: "1.5rem",
                      color: "gray",
                      marginLeft: "1rem",
                    }}
                  />
                </button>
              </div>
            </div>

            <h5>
              <font color="#a1a5a1">{this.state.product.itemCode}</font>
            </h5>
            <br />
            <h3>
              Rs:{" "}
              {(this.state.product.price *
                (100 -
                  (this.state.product.isNoDiscount
                    ? 5
                    : 5 + this.state.discount))) /
                100}
            </h3>

            <div style={{ color: "#b30000", fontSize: "1.3rem" }}>
              <del>Rs: {this.state.product.price}</del>{" "}
              <span
                style={{
                  borderRadius: "0.2rem",
                  color: "white",
                  backgroundColor: "#b30000",
                  fontSize: "1rem",
                  padding: "0.02rem 0.3rem 0.1rem 0.3rem",
                  marginLeft: "0.5rem",
                }}
              >
                -{this.state.product.isNoDiscount ? 5 : 5 + this.state.discount}
                %
              </span>
            </div>

            <br />
            <span>
              <h5>Availability:</h5>
              {this.state.product.quantity > 0 ? (
                <font color="#3bce34">In stock</font>
              ) : (
                <font color="red">Out of stock</font>
              )}
            </span>
            <hr />
            <div className="row">
              <div className="col-12">
                <button
                  className="count-button"
                  onClick={() => this.counter(-1)}
                >
                  -
                </button>
                <input
                  className="count"
                  id="result"
                  name="count"
                  value={this.state.product.quantity > 0 ? this.state.count : 0}
                  readOnly
                />
                <button
                  className="count-button"
                  onClick={() => this.counter(1)}
                >
                  +
                </button>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12 text-dark font-weight-bold">
                Total :- Rs.{" "}
                {(this.state.total *
                  (100 -
                    (this.state.product.isNoDiscount
                      ? 5
                      : 5 + this.state.discount))) /
                  100}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <button
                  disabled={this.state.product.quantity < 1}
                  className="btn btn-dark mx-2"
                  onClick={() =>
                    this.props.isLoggedIn
                      ? this.handleCart()
                      : document.getElementById("authpopupDetails").click()
                  }
                >
                  <i className="fas fa-cart-arrow-down"></i> Add to cart
                </button>
                <Link
                  disabled={this.state.product.quantity < 1}
                  to={{
                    pathname: "/placeorder",
                    data: {
                      selectedItems: [this.state.product.itemCode],
                      quantity: [
                        {
                          itemCode: this.state.product.itemCode,
                          price: this.state.product.price,
                          quantity: this.state.count,
                        },
                      ],
                      total: this.state.total,
                    },
                  }}
                >
                  <button
                    className="btn btn-dark mx-2"
                    disabled={this.state.product.quantity < 1}
                  >
                    Buy Now
                  </button>
                </Link>
              </div>
            </div>

            <hr />
            <h4>
              <font color="#000000">PRODUCT DESCRIPTION</font>
            </h4>
            <div>
              <p> {ReactHtmlParser(this.state.product.description)} </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  isLoggedIn: state.auth.isLoggedIn,
  addToCart: state.addToCart,
});

export default connect(mapStateToProps, { handleAddToCart })(Details);
