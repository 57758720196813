import React, { Component } from "react";
import "../../css/Login.css";
import { connect } from "react-redux";
import { handleLogin, handleSignup } from "../../actions/authAction";
import { Redirect } from "react-router";

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      email: "",
      password: "",
      isSignUp: false,
      fname: "",
      lname: "",
      password1: ""
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);

    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  componentDidMount() {
    this.updateWindowDimensions();

    window.addEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ height: window.innerHeight });
  }
  handleLogin(e) {
    e.preventDefault();
    this.props.handleLogin({
      email: this.state.email,
      password: this.state.password
    });
  }
  handleSignUp(e) {
    e.preventDefault();
    this.props.handleSignup({
      fullName: this.state.fname + " " + this.state.lname,
      email: this.state.email,
      password: this.state.password
    });
  }

  render() {
    if (this.state.isSignUp && this.state.password.length > 0) {
      try {
        if (this.state.password !== this.state.password1) {
          document
            .getElementById("password2")
            .setCustomValidity("Passwords Don't Match");
        } else {
          document.getElementById("password2").setCustomValidity("");
        }
      } catch (error) {}
    }
    if (this.props.loginSuccess) {
      this.props.handleClose();
    }

    return (
      <React.Fragment>
        {this.props.currentUser.user_id &&
        this.props.currentUser.user_id.length > 0 &&
        this.props.currentUser.is_admin ? (
          <Redirect to="/admin" />
        ) : null}

        <div className="container-fluid" style={{ color: "white" }}>
          {this.props.userNotFound ? (
            <div
              className="mx-auto my-2 text-center"
              style={{ maxWidth: "25rem" }}
            >
              <div className="alert alert-danger" role="alert">
                Invalid email or password !
              </div>
            </div>
          ) : this.props.loginSuccess ? (
            <div
              className="mx-auto my-2 text-center"
              style={{ maxWidth: "25rem" }}
            >
              <div className="alert alert-success" role="alert">
                Sign In successful !
              </div>
            </div>
          ) : null}
          <div
            className="card-login m-auto p-4"
            style={
              this.state.isSignUp
                ? {
                    maxWidth: "40rem"
                  }
                : {
                    maxWidth: "25rem"
                  }
            }
          >
            <div className="card-login-header mb-4">
              {this.state.isSignUp ? (
                <h3>Create An Account</h3>
              ) : (
                <h3>Sign In</h3>
              )}
            </div>
            <div className="card-login-body text-center mt-4">
              <form
                onSubmit={
                  this.state.isSignUp ? this.handleSignUp : this.handleLogin
                }
              >
                {this.state.isSignUp ? (
                  <React.Fragment>
                    <div className="form-row text-left">
                      <div className="form-group col-md-6">
                        <label htmlFor="fname">First name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fname"
                          placeholder="First Name"
                          required
                          value={this.state.fname}
                          onChange={e =>
                            this.setState({ fname: e.target.value })
                          }
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="lname">Last name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="lname"
                          placeholder="Last Name"
                          required
                          value={this.state.lname}
                          onChange={e =>
                            this.setState({ lname: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group text-left">
                      <label htmlFor="email">Email address</label>
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        id="email"
                        aria-describedby="emailHelp"
                        placeholder="Email"
                        required
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                      />
                    </div>
                    <div className="form-group text-left">
                      <label htmlFor="password1">Password</label>
                      <input
                        name="password"
                        type="password"
                        className="form-control"
                        id="password1"
                        placeholder="Password"
                        required
                        value={this.state.password}
                        onChange={e =>
                          this.setState({ password: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group text-left">
                      <label htmlFor="password2">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="password2"
                        placeholder="Password"
                        required
                        value={this.state.password1}
                        onChange={e =>
                          this.setState({ password1: e.target.value })
                        }
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="input-group form-group my-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                      <input
                        type="email"
                        className="form-control "
                        placeholder="email"
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                      />
                    </div>
                    <div className="input-group form-group my-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-key"></i>
                        </span>
                      </div>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="password"
                        value={this.state.password}
                        onChange={e =>
                          this.setState({ password: e.target.value })
                        }
                      />
                    </div>
                  </React.Fragment>
                )}
                <div className="form-group m-auto">
                  <button
                    type="submit"
                    className="btn login_btn btn-dark btn-lg w-50 my-4"
                  >
                    {this.state.isSignUp ? "Sign Up" : "Sign In"}
                  </button>
                </div>
              </form>
            </div>

            <div className="card-login-footer">
              {this.state.isSignUp ? (
                <div className="d-flex justify-content-center links">
                  Already Registered ?
                  <button
                    onClick={() => this.setState({ isSignUp: false })}
                    className="ml-2 btn-sm"
                    style={{
                      color: "#3385ff",
                      fontWeight: "bold",
                      background: "none",
                      border: "none"
                    }}
                  >
                    Sign In
                  </button>
                </div>
              ) : (
                <div className="d-flex justify-content-center links">
                  <small>Don't have an account ?</small>
                  <button
                    onClick={() => this.setState({ isSignUp: true })}
                    className="ml-2 btn-sm"
                    style={{
                      color: "#3385ff",
                      fontWeight: "bold",
                      background: "none",
                      border: "none"
                    }}
                  >
                    Sign Up
                  </button>
                </div>
              )}
              {
                //   <div className="d-flex justify-content-center">
                //   <a href="#">Forgot your password?</a>
                // </div>
              }
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  userNotFound: state.auth.userNotFound,
  loginSuccess: state.auth.loginSuccess
});

export default connect(mapStateToProps, { handleLogin, handleSignup })(Auth);
