import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "./App.css";
import Deafault from "./components/pages/Default";
import User from "./components/User";
import Admin from "./components/Admin";
import AuthMain from "./components/auth/AuthMain";
import { connect } from "react-redux";
import { getProfileFetch } from "./actions/authAction";
import AuthPopup from "./components/auth/AuthPopup";
import AddToCart from "./components/common/AddToCart";

class App extends Component {
  constructor(props) {
    super(props);
    this.props.getProfileFetch();
  }

  render() {
    return (
      <React.Fragment>
        {
          // console.log("Login status: " + this.props.isLoggedIn)
        }
        <button
          data-toggle="modal"
          data-target="#addToCartPopup"
          type="button"
          id="popupcart"
          style={{ display: "none" }}
        ></button>

        <AddToCart />
        <button
          data-toggle="modal"
          data-target="#authPopup"
          type="button"
          id="authpopupDetails"
          style={{ display: "none" }}
        ></button>
        <AuthPopup />
        <Switch>
          <Route path="/admin/" component={Admin} />
          <Route path="/auth" component={AuthMain} />
          <Route path="/" component={User} />
          <Route component={Deafault}></Route>
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn
});

export default connect(mapStateToProps, { getProfileFetch })(App);
