import React, { Component } from "react";
import DisplayShippingInfo from "./DisplayShippingInfo";
import EditShippingInfo from "./EditShippingInfo";
import axios from "axios";
import { connect } from "react-redux";
import PlaceOrderPopUp from "./PlaceOrderPopUp";

class PlaceOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: {
        fullName: "",
        phoneNumber: "",
        address: "",
        address2: "",
        city: "",
        country: "",
      },
      isAddressAddAsDefault: true,
      email: "",
      selectedItems: this.props.location.data
        ? this.props.location.data.selectedItems
        : [],
      cart: [],
      isEditShippingInfo: false,
      paymentMethod: "Bank",
      isValidPromoCode: true,
      orderSuccessfullyPlaced: false,
      uploading: true,
      orderID: "",
      orderType: "",
      coupnDiscount: 0,
      shippingDiscount: 5,
      promoCode: "",
      subTotal: 0,
      productsDiscount: 0,
      discount: 0,
    };
    if (!this.props.location.data) {
      this.props.history.push("/");
    } else {
      this.getDataFromDB();
    }
    this.setEdit = this.setEdit.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.setDefaultAddress = this.setDefaultAddress.bind(this);
    this.getDataFromDB = this.getDataFromDB.bind(this);
    this.changePaymentM = this.changePaymentM.bind(this);
    this.handlePromoCode = this.handlePromoCode.bind(this);
    this.setPromoCode = this.setPromoCode.bind(this);
    this.handlePopupclose = this.handlePopupclose.bind(this);
    this.calculateTotalAndSetCart = this.calculateTotalAndSetCart.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  setDefaultAddress() {
    axios
      .patch(
        "/users/updateAddress",
        {
          userID: this.props.currentUser.user_id,
          Address: this.state.address,
        },
        {
          headers: {
            authorization: localStorage.c,
          },
        }
      )
      .then((response) => {
        console.log("Success");
        this.setState({
          isAddressAddAsDefault: true,
        });
      })
      .catch((err) => {
        console.log("Error: " + err);
      });
  }
  setAddress(address, email) {
    this.setState({
      address: address,
      email: email,
      isAddressAddAsDefault: false,
    });
  }
  calculateTotalAndSetCart(cart) {
    var total = 0;
    var discount = 0;
    cart.forEach((element) => {
      total += element.price * element.quantity;
      if (!element.isNoDiscount) {
        discount +=
          (element.price * element.quantity * this.state.discount) / 100;
      }
    });
    this.setState({
      cart: cart,
      subTotal: total,
      productsDiscount: discount,
    });
  }
  getDataFromDB() {
    axios
      .get("/settings/")
      .then((res) => {
        if (res.data === null) {
          this.setState({
            error: true,
          });
        } else {
          this.setState({
            discount: res.data.discount,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("/products/", {
        headers: {
          authorization: localStorage.c,
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          var cart = [];
          this.props.location.data.quantity.forEach((element) => {
            var product = response.data.find(
              (i) => i.itemCode === element.itemCode
            );
            if (product) {
              cart.push({
                ...element,
                title: product.title,
                category: product.category,
                mainImage: product.mainImage,
                isNoDiscount: product.isNoDiscount,
              });
            }
          });
          this.calculateTotalAndSetCart(cart);
        }
      });

    axios
      .get("/users/address/" + this.props.currentUser.user_id, {
        headers: {
          authorization: localStorage.c,
        },
      })
      .then((response) => {
        if (response.data) {
          this.setState({
            email: response.data.email,
          });
          if (response.data.address.fullName) {
            this.setState({
              address: response.data.address,
            });
          } else {
            this.setState({
              isEditShippingInfo: true,
            });
          }
        } else {
          this.setState({
            isEditShippingInfo: true,
          });
        }
      })
      .catch((err) => {
        console.log("error: " + err);
        this.setState({
          isEditShippingInfo: true,
        });
      });
  }
  setEdit() {
    this.setState({ isEditShippingInfo: !this.state.isEditShippingInfo });
  }
  changePaymentM(val) {
    if (val === "COD") {
      this.setState({
        paymentMethod: val,
        shippingDiscount: 0,
      });
    } else {
      this.setState({
        paymentMethod: val,
        shippingDiscount: 5,
      });
    }
  }
  handleRemoveItem(item) {
    var cart = this.state.cart.filter((i) => {
      return i.itemCode !== item;
    });
    var itemD = this.state.cart.find((i) => {
      return i.itemCode === item;
    });

    this.calculateTotalAndSetCart(cart);
    this.setState({
      subTotal: this.state.subTotal - itemD.price * itemD.quantity,
    });
  }
  handlePromoCode(e) {
    if (false) {
      this.setState({
        isValidPromoCode: true,
        coupnDiscount: 20,
      });
    } else {
      this.setState({
        isValidPromoCode: false,
        coupnDiscount: 0,
      });
    }
  }
  setPromoCode(e) {
    this.setState({
      promoCode: e.target.value,
    });
  }
  submitOrder() {
    this.setState({ uploading: true, orderSuccessfullyPlaced: false });
    document.getElementById("poPopup").click();
    axios
      .post(
        "/orders/addNew",
        {
          fullName: this.state.address.fullName,
          phoneNumber: this.state.address.phoneNumber,
          userID: this.props.currentUser.user_id,
          email: this.state.email,
          address: this.state.address.address,
          address2: this.state.address.address2,
          city: this.state.address.city,
          country: this.state.address.country,
          paymentMethod: this.state.paymentMethod,
          promoCode: this.state.promoCode,
          Payment:
            this.state.subTotal -
            this.state.productsDiscount -
            (this.state.subTotal *
              (this.state.coupnDiscount + this.state.shippingDiscount)) /
              100,
          items: this.state.cart,
        },
        {
          headers: {
            authorization: localStorage.c,
          },
        }
      )
      .then((response) => {
        axios
          .patch("/products/updatePendingItems/", { items: this.state.cart })
          .then((res) => {
            this.setState({
              orderSuccessfullyPlaced: true,
              uploading: false,
              orderID: response.data.orderID,
              orderType: response.data.orderType,
            });
          })
          .catch((err) => {
            console.log("Error: " + err);
          });
      })
      .catch((err) => {
        console.log("Error: " + err);
      });

    axios
      .patch(
        "/users/removebulkFromCart",
        {
          userID: this.props.currentUser.user_id,
          cart: this.state.selectedItems,
        },
        {
          headers: {
            authorization: localStorage.c,
          },
        }
      )
      .then((response) => {
        // console.log(response);
      })
      .catch((err) => {
        console.log("Error:" + err);
      });
  }
  handlePopupclose() {
    this.setState({ orderSuccessfullyPlaced: false, orderID: "" });
  }

  render() {
    return (
      <div className="container-fluid placeOrder page-body">
        <div className="container-fluid">
          <button
            data-toggle="modal"
            data-target="#placeOrderPopup"
            type="button"
            id="poPopup"
            style={{ display: "none" }}
          ></button>
          <PlaceOrderPopUp
            handlePopupclose={this.handlePopupclose}
            orderSuccessfullyPlaced={this.state.orderSuccessfullyPlaced}
            uploading={this.state.uploading}
            orderID={this.state.orderID}
            orderType={this.state.orderType}
          />
          <div className="row">
            <div className="col-md-9 mb-3">
              <div className="my-3">
                <div
                  className="c p-3"
                  style={{
                    maxWidth: "100%",
                    // height: "16rem",
                    border: "solid 1px gray",
                    borderRadius: "5px",
                  }}
                >
                  <h6>Shipping Information</h6>
                  <hr className="mt-0 mb-2 mx-2" />
                  <div className="body px-2 ml-4">
                    {this.state.isEditShippingInfo ? (
                      <EditShippingInfo
                        address={this.state.address}
                        email={this.state.email}
                        setAddress={this.setAddress}
                        setEdit={this.setEdit}
                      />
                    ) : (
                      <DisplayShippingInfo
                        setDefaultAddress={this.setDefaultAddress}
                        address={this.state.address}
                        email={this.state.email}
                        setEdit={this.setEdit}
                        isAddressAddAsDefault={
                          this.props.isLoggedIn
                            ? this.state.isAddressAddAsDefault
                            : true
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="my-3">
                <div
                  className="c p-3"
                  style={{
                    maxWidth: "100%",
                    // height: "16rem",
                    border: "solid 1px gray",
                    borderRadius: "5px",
                  }}
                >
                  <h6>Payment method</h6>
                  <hr className="mt-0 mb-2 mx-2" />
                  <div className="body px-2 ml-2">
                    <div className="custom-control custom-radio m-2">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="paymentOption1"
                        name="paymentMethod"
                        value="bankPayment"
                        onClick={() => {
                          this.changePaymentM("Bank");
                        }}
                        defaultChecked={this.state.paymentMethod === "Bank"}
                      />

                      <label
                        className="custom-control-label"
                        htmlFor="paymentOption1"
                      >
                        Bank Payment <strong>( 5% Discount )</strong>
                      </label>
                    </div>
                    <div className="custom-control custom-radio m-2">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="paymentOption2"
                        name="paymentMethod"
                        value="COD"
                        onClick={() => {
                          this.changePaymentM("COD");
                        }}
                        defaultChecked={this.state.paymentMethod === "COD"}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="paymentOption2"
                      >
                        Cash On Delivery{" "}
                        {/* <strong>( {this.state.discount - 5}% Discount )</strong> */}
                      </label>
                    </div>

                    {this.state.paymentMethod === "Bank" ? (
                      <div
                        style={{
                          maxWidth: "90%",

                          border: "solid 2px #1aa3ff",
                          borderRadius: "5px",
                          margin: "auto",
                          padding: "0.5rem",
                        }}
                        className="message"
                      >
                        <p>
                          Bank : Peoples Bank <br />
                          Branch : Delgoda <br />
                          Acc. Name : P.D.M.S. Panapitiya <br />
                          Acc. No : 118-2-002-60039275
                        </p>

                        <p>
                          Please send an image of the deposit/transfer slip and
                          the order number to
                          <strong> orders@klover.lk</strong>
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="my-3">
                <div
                  className="c p-3"
                  style={{
                    maxWidth: "100%",
                    border: "solid 1px gray",
                    borderRadius: "5px",
                  }}
                >
                  <h6>Review Your Order</h6>
                  <hr className="mt-0 mb-2 mx-2" />
                  <div className="body px-2 ml-4">
                    {this.state.cart.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="c p-2 m-auto"
                          style={{
                            maxWidth: "100%",
                            border: "solid 1px gray",
                            borderRadius: "5px",
                          }}
                        >
                          <div className="row no-gutters">
                            <div className="col-sm-2 text-center">
                              <img
                                src={item.mainImage}
                                alt="product"
                                className="img-fluid img-thumbnail"
                                style={{
                                  maxHeight: "100px",
                                  maxWidth: "100%",
                                }}
                              />
                            </div>
                            <div className="col-sm-4 my-auto text-center">
                              <h5 className="c-title">
                                {item.title} -
                                <small className="text-muted">
                                  {item.itemCode}
                                </small>
                              </h5>
                            </div>

                            <div className="col-sm-4 my-auto text-center">
                              Rs: {item.price} x {item.quantity}{" "}
                              <span
                                style={{
                                  color: "#b30000",
                                  fontSize: "0.8rem",
                                }}
                              >
                                {`(-${
                                  (this.state.paymentMethod === "Bank"
                                    ? 5
                                    : 0) +
                                  (item.isNoDiscount ? 0 : this.state.discount)
                                }%)`}
                              </span>
                            </div>

                            <div className="col-sm-1 text-center my-auto">
                              <span
                                onClick={() =>
                                  this.handleRemoveItem(item.itemCode)
                                }
                                className="remove"
                              >
                                <i className="fas fa-trash-alt" />
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="sticky-top" style={{ top: "7em" }}>
                <div className="my-3">
                  <div
                    className="c p-3"
                    style={{
                      maxWidth: "100%",
                      // height: "16rem",
                      border: "solid 1px gray",
                      borderRadius: "5px",
                    }}
                  >
                    <h6>Order Summery</h6>
                    <hr className="mt-0 mb-2 mx-2" />
                    <div className="body px-2">
                      <small className="mr-5">Subtotal : </small>
                      <small style={{ right: 0 }}>
                        Rs {this.state.subTotal}
                      </small>

                      <br />
                      <small className="mr-5">Shipping :</small>
                      <small style={{ right: 0 }}>FREE</small>

                      <br />
                      {false && (
                        <div className="m-1">
                          <input
                            type="text"
                            className="promoCode"
                            id="promoCode"
                            name="promoCode"
                            placeholder="Promo Code"
                            value={this.state.promoCode}
                            style={{
                              fontSize: "0.8rem",
                              height: "1.2rem",
                              width: "6rem",
                              margin: "0 0 0 0.3rem",
                              padding: 0,
                            }}
                            onChange={this.setPromoCode}
                          />

                          <button
                            className="btn-dark btn"
                            style={{
                              fontSize: "0.8rem",
                              height: "1.2rem",
                              padding: "0 0.3rem 0 0.3rem",
                              margin: "0 0 0 0.3rem",
                            }}
                            onClick={this.handlePromoCode}
                          >
                            Apply
                          </button>
                          {this.state.isValidPromoCode ? null : (
                            <div
                              style={{
                                maxWidth: "90%",

                                border: "solid 1px gray",
                                borderRadius: "5px",
                                margin: "0.5rem 0 0 0",
                                borderColor: "red",
                                padding: "0 0.2rem 0 0.2rem",
                                textAlign: "center",
                              }}
                              className="promoMessage"
                            >
                              <small className="">Invalid Code</small>
                            </div>
                          )}
                        </div>
                      )}
                      <small className="mr-3 font-weight-bold">
                        Discount :{" "}
                      </small>

                      <small className=" font-weight-bold">
                        Rs{" "}
                        {this.state.productsDiscount +
                          (this.state.subTotal *
                            (this.state.coupnDiscount +
                              this.state.shippingDiscount)) /
                            100}{" "}
                        (
                        {parseInt(
                          ((this.state.productsDiscount +
                            (this.state.subTotal *
                              (this.state.coupnDiscount +
                                this.state.shippingDiscount)) /
                              100) *
                            100) /
                            this.state.subTotal
                        )}
                        %)
                      </small>
                      <hr className="m-2" />
                      <h4 className="my-2">
                        Total : Rs{" "}
                        {this.state.subTotal -
                          this.state.productsDiscount -
                          (this.state.subTotal *
                            (this.state.coupnDiscount +
                              this.state.shippingDiscount)) /
                            100}
                      </h4>
                      <div className="text-center">
                        <button
                          disabled={
                            this.state.address.fullName.length < 1 ||
                            this.state.paymentMethod.length < 1 ||
                            this.state.subTotal < 1
                          }
                          onClick={this.submitOrder}
                          className="btn btn-dark w-75 "
                        >
                          PLACE ORDER
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps, {})(PlaceOrder);
