import React, { Component } from "react";
import Product from "./common/Product";
import axios from "axios";

class OfficeWear extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],

      discount: 0,
      discountImage: "",
    };
  }
  componentDidMount() {
    axios.get("/products/office").then((response) => {
      if (response.data.length > 0) {
        this.setState({
          products: response.data,
        });
      }
    });
    axios
      .get("/settings/")
      .then((res) => {
        if (res.data === null) {
          this.setState({
            error: true,
          });
        } else {
          // console.log(res);
          this.setState({
            discount: res.data.discount,
            discountImage: res.data.discountImage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="page-body productList">
        <div className="container-fluid">
          <div className="container-fluid main-title text-uppercase">
            <div className="row">
              <div className="col-12">Office Wear</div>
            </div>
            <hr />
          </div>
          <div className="row no-gutters">
            {this.state.products.map((product, index) => {
              return (
                <div
                  key={index}
                  className="col-6 col-md-4 col-lg-3 my-3 px-md-3 px-1 mx-auto"
                >
                  <Product
                    product={product}
                    discount={this.state.discount}
                    discountImage={this.state.discountImage}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default OfficeWear;
