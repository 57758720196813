import React, { Component } from "react";
import axios from "axios";

class SlideshowMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image1: "",
      image2: "",
      image3: "",
      error: ""
    };
  }

  componentDidMount() {
    axios
      .get("/slideshow/")
      .then(res => {
        if (res.data === null) {
          this.setState({
            error: true
          });
        } else {
          this.setState({
            image1: res.data.image1,
            image2: res.data.image2,
            image3: res.data.image3,
            error: false
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
  render() {
    return (
      <div
        id="mainSlideShow"
        className="main-slideshow carousel slide"
        data-ride="carousel"
      >
        <ul className="carousel-indicators mb-0">
          <li
            data-target="#mainSlideShow"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#mainSlideShow" data-slide-to="1"></li>
          <li data-target="#mainSlideShow" data-slide-to="2"></li>
        </ul>

        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              className="d-block w-100"
              src={this.state.image1}
              alt="Klover"
              width="100%"
              height="100%"
            />
          </div>
          <div className="carousel-item">
            <img
              className="d-block w-100"
              src={this.state.image2}
              alt="Klover"
              width="100%"
              height="100%"
            />
          </div>
          <div className="carousel-item">
            <img
              className="d-block w-100"
              src={this.state.image3}
              alt="Klover"
              width="100%"
              height="100%"
            />
          </div>
        </div>

        <a
          className="carousel-control-prev"
          href="#mainSlideShow"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon"></span>
        </a>
        <a
          className="carousel-control-next"
          href="#mainSlideShow"
          data-slide="next"
        >
          <span className="carousel-control-next-icon"></span>
        </a>
      </div>
    );
  }
}

export default SlideshowMain;
