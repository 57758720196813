import React, { Component } from "react";
import "../../css/Home.css";
import { Link } from "react-router-dom";
import SlideshowMain from "./Slideshow-main";
import SlideshowProduct from "./Slideshow-product";
import axios from "axios";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partySlideshow: [],
      newArrivalSlideshow: [],
      designerSlideshow: [],
      bridalSlideshow: [],
      officeSlideshow: [],
      discount: 0,
      discountImage: "",
    };
    this.getdata();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  getdata() {
    axios.get("/products/newArrivalSlideshow").then((response) => {
      if (response.data.length > 0) {
        this.setState({
          newArrivalSlideshow: response.data,
        });
      }
    });
    axios.get("/products/party").then((response) => {
      if (response.data.length > 0) {
        this.setState({
          partySlideshow: response.data,
        });
      }
    });
    axios.get("/products/designer").then((response) => {
      if (response.data.length > 0) {
        this.setState({
          designerSlideshow: response.data,
        });
      }
    });
    axios.get("/products/office").then((response) => {
      if (response.data.length > 0) {
        this.setState({
          officeSlideshow: response.data,
        });
      }
    });
    axios.get("/products/bridal").then((response) => {
      if (response.data.length > 0) {
        this.setState({
          bridalSlideshow: response.data,
        });
      }
    });
    axios
      .get("/settings/")
      .then((res) => {
        if (res.data === null) {
          this.setState({
            error: true,
          });
        } else {
          // console.log(res);
          this.setState({
            discount: res.data.discount,
            discountImage: res.data.discountImage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    return (
      <div className="home">
        <SlideshowMain />
        {this.state.newArrivalSlideshow.length > 0 ? (
          <React.Fragment>
            <div className="container-fluid main-title text-uppercase">
              <div className="row">
                <div className="col-12">
                  <Link to="/newArrivals" style={{ color: "black" }}>
                    New Arrivals
                  </Link>
                </div>
              </div>
              <hr />
            </div>
            <SlideshowProduct
              id="newArrival"
              products={this.state.newArrivalSlideshow}
              discount={this.state.discount}
              discountImage={this.state.discountImage}
            />
          </React.Fragment>
        ) : null}
        {this.state.designerSlideshow.length > 0 ? (
          <React.Fragment>
            <div className="container-fluid main-title text-uppercase">
              <div className="row">
                <div className="col-12">
                  <Link to="/designer-wear" style={{ color: "black" }}>
                    Designer Wear
                  </Link>
                </div>
              </div>
              <hr />
            </div>
            <SlideshowProduct
              id="designerWear"
              products={this.state.designerSlideshow}
              discount={this.state.discount}
              discountImage={this.state.discountImage}
            />
          </React.Fragment>
        ) : null}
        {this.state.partySlideshow.length > 0 ? (
          <React.Fragment>
            <div className="container-fluid main-title text-uppercase">
              <div className="row">
                <div className="col-12">
                  <Link to="/party-wear" style={{ color: "black" }}>
                    Party Wear
                  </Link>
                </div>
              </div>
              <hr />
            </div>
            <SlideshowProduct
              id="partyWear"
              products={this.state.partySlideshow}
              discount={this.state.discount}
              discountImage={this.state.discountImage}
            />
          </React.Fragment>
        ) : null}{" "}
        {this.state.bridalSlideshow.length > 0 ? (
          <React.Fragment>
            <div className="container-fluid main-title text-uppercase">
              <div className="row">
                <div className="col-12">
                  <Link to="/bridal-wear" style={{ color: "black" }}>
                    Bridal Wear
                  </Link>
                </div>
              </div>
              <hr />
            </div>
            <SlideshowProduct
              id="bridalWear"
              products={this.state.bridalSlideshow}
              discount={this.state.discount}
              discountImage={this.state.discountImage}
            />
          </React.Fragment>
        ) : null}
        {this.state.officeSlideshow.length > 0 ? (
          <React.Fragment>
            <div className="container-fluid main-title text-uppercase">
              <div className="row">
                <div className="col-12">
                  <Link to="/office-wear" style={{ color: "black" }}>
                    Office Wear
                  </Link>
                </div>
              </div>
              <hr />
            </div>
            <SlideshowProduct
              id="officeWear"
              products={this.state.officeSlideshow}
              discount={this.state.discount}
              discountImage={this.state.discountImage}
            />
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

export default Home;
