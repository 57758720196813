import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware, compose } from "redux";
import allReducer from "./reducers";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

const initialState = {};
const middleware = [thunk];

const store = createStore(
  allReducer,
  initialState,
  compose(
    applyMiddleware(...middleware)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://m.me/kloversaree?fbclid=IwAR2_7nbiSdFf9IsgjCUWIe9itj9mYXlQano7qvTAeymEfKhDCJ3xBTd2Vso"
      >
        <img
          src="/img/msngr.png"
          alt="Messenger klover"
          width="50"
          id="fixedbutton"
        />
      </a>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
