import React, { Component } from "react";
import axios from "axios";

class MyOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [0, 1, 2, 3, 4, 5],
      data: [],
      orderStatus: "All",
      orderID: "",
      isInvalidOrderID: false,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.getData();
  }
  getData() {
    axios
      .get("/orders/user", {
        headers: {
          authorization: localStorage.c,
        },
      })
      .then((res) => {
        if (res.data === null) {
          this.setState({
            error: true,
          });
        } else {
          this.setState({
            data: res.data,
            error: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSearch() {
    if (this.state.orderStatus !== "All") {
      axios
        .get("/orders/user/status/" + this.state.orderStatus, {
          headers: {
            authorization: localStorage.c,
          },
        })
        .then((res) => {
          if (res.data === null) {
            this.setState({
              error: true,
            });
          } else {
            this.setState({
              data: res.data,
              error: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get("/orders/user", {
          headers: {
            authorization: localStorage.c,
          },
        })
        .then((res) => {
          if (res.data === null) {
            this.setState({
              error: true,
            });
          } else {
            this.setState({
              data: res.data,
              error: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  render() {
    return (
      <div className="container-fluid page-body">
        <div className="container-fluid main-title text-uppercase">
          <div className="row">
            <div className="col-12">My Orders</div>
          </div>
          <hr />
        </div>

        <div
          className="row p-2 m-2"
          style={{ border: "1px solid gray", borderRadius: "10px" }}
        >
          <div className="col-sm-2 col-4 offset-sm-1 px-1 text-right ">
            <div className=" m-auto">
              <label htmlFor="orderStatus">Order Status :</label>
            </div>
          </div>
          <div className="col-sm-4 col-6 offset-sm-1 px-1 text-left">
            <div className=" m-auto">
              <select
                className="form-control"
                id="orderStatus"
                value={this.state.orderStatus}
                disabled={this.state.orderID.length === 0 ? false : true}
                onChange={(e) => this.setState({ orderStatus: e.target.value })}
              >
                <option value="All">All</option>
                <option value="Pending">Pending</option>
                <option value="Processing">Processing</option>
                <option value="On Delivery">On Delivery</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Completed">Completed</option>
              </select>
            </div>
          </div>

          <div className="col-sm-3 col-10 px-4 text-center m-auto">
            <button onClick={this.handleSearch} className="btn btn-dark m-auto">
              Search
            </button>
          </div>
        </div>
        <hr
          style={{
            height: "1px",
            backgroundColor: "gray",
            marginRight: "10%",
            marginLeft: "10%",
          }}
        />
        <div
          className="grid"
          style={{
            overflowX: "scroll",
            padding: "0px 15px 15px 15px",
          }}
        >
          <div
            className="row text-center"
            style={{
              border: "1px solid black",
              borderRadius: "10px",
              overflow: "hidden",
              fontSize: "0.9rem",
              minWidth: "1040px",
            }}
          >
            <div className="col-12">
              <div
                className="row"
                style={{
                  borderBottom: "1px solid black",
                  backgroundColor: "gray",
                  color: "white",
                }}
              >
                <div
                  className="col-3 p-2"
                  style={{ borderRight: "1px solid black" }}
                >
                  Date
                </div>
                <div
                  className="col-1 p-2"
                  style={{ borderRight: "1px solid black" }}
                >
                  Order ID
                </div>

                <div
                  className="col-1 p-2"
                  style={{ borderRight: "1px solid black" }}
                >
                  Item Code
                </div>
                <div
                  className="col-1 p-2"
                  style={{ borderRight: "1px solid black" }}
                >
                  Quantity
                </div>
                <div
                  className="col-1 py-2 px-0"
                  style={{ borderRight: "1px solid black" }}
                >
                  Order Type
                </div>
                <div
                  className="col-1 p-2"
                  style={{ borderRight: "1px solid black" }}
                >
                  Payment
                </div>
                <div
                  className="col-2 p-2"
                  style={{ borderRight: "1px solid black" }}
                >
                  Order Status
                </div>
                <div
                  className="col-1 p-2"
                  style={{ borderRight: "1px solid black" }}
                >
                  Tracking
                </div>
              </div>
              {this.state.data.length > 0 ? (
                this.state.data.map((order, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className="row"
                        style={{
                          borderBottom: "1px solid gray",
                          borderTop: "1.5px solid black",
                        }}
                      >
                        <div
                          className="col-3 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          {new Date(order.date).toLocaleString("en-US", {
                            timeZone: "Asia/Kolkata",
                          })}
                        </div>
                        <div
                          className="col-1 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          {order.orderID}
                        </div>

                        <div className="col-2">
                          {order.items.map((item, i) => {
                            return (
                              <div key={index + "c" + i} className="row">
                                <div
                                  className="col-6 p-2"
                                  style={
                                    i !== 0
                                      ? {
                                          borderRight: "1px solid black",
                                          borderTop: "1px solid black",
                                        }
                                      : {
                                          borderRight: "1px solid black",
                                        }
                                  }
                                >
                                  {item.itemCode}
                                </div>
                                <div
                                  className="col-6 p-2"
                                  style={
                                    i !== 0
                                      ? {
                                          borderRight: "1px solid black",
                                          borderTop: "1px solid black",
                                        }
                                      : {
                                          borderRight: "1px solid black",
                                        }
                                  }
                                >
                                  {item.quantity}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div
                          className="col-1 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          {order.paymentMethod}
                        </div>
                        <div
                          className="col-1 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          Rs: {order.Payment}
                        </div>
                        <div
                          className="col-2 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          {order.orderStatus}
                        </div>
                        <div
                          className="col-1 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          {(order.wayBillNumber !== "" &&
                            order.orderStatus === "On Delivery") ||
                          order.orderStatus === "Completed" ? (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={
                                "http://domex.lk/tracking.php?wbno=" +
                                order.wayBillNumber
                              }
                              style={{ textDecoration: "none" }}
                            >
                              <i
                                style={{ color: "black", fontSize: "1.5rem" }}
                                className="fas fa-truck"
                              ></i>
                            </a>
                          ) : (
                            "Pending"
                          )}
                        </div>
                        <div className="col-1 p-2">
                          <button
                            className="remove"
                            data-toggle="collapse"
                            data-target={"#order" + index}
                            aria-expanded="false"
                            aria-controls={"order" + index}
                            style={{ background: "none", border: "none" }}
                          >
                            <i className="fas fa-chevron-down"></i>
                          </button>
                        </div>
                      </div>
                      <div
                        className="row py-2 text-left collapse"
                        id={"order" + index}
                        style={{
                          borderBottom: "1px solid gray",
                          paddingLeft: "2rem",
                          paddingRight: "2rem",
                        }}
                      >
                        <div className="col-5">
                          <div className="row">
                            <div className="col-12">
                              <strong>Name :</strong> {order.fullName}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <strong>Address :</strong>
                              <span>
                                {" "}
                                {order.address}, {order.address2}, {order.city},{" "}
                                {order.country}.
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="row">
                            <div className="col-12">
                              <strong>Tel :</strong> {order.phoneNumber}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <strong>Email :</strong> {order.email}
                            </div>
                          </div>
                        </div>

                        <div className="col-3">
                          <div className="row">
                            <div className="col-12">
                              <strong>Promo Code :</strong> {order.promoCode}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <strong>Waybill number :</strong>{" "}
                              {order.wayBillNumber}
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })
              ) : (
                <div
                  className="row"
                  style={{
                    borderBottom: "1px solid gray",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <div
                    className="col-12 text-center p-2"
                    style={{ borderRight: "1px solid black" }}
                  >
                    No Data
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MyOrders;
