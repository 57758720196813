import React, { Component } from "react";
import Auth from "./Auth";

class AuthPopup extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    document.getElementById("closeAuthPopup").click();
  }

  render() {
    return (
      <div className="Authpopup ">
        <div
          className="modal fade p-0"
          id="authPopup"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="authPopupTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered p-0"
            role="document"
          >
            <div className="modal-content  bg-dark m-0 p-0">
              <div className="text-right ">
                <button
                  type="button"
                  className="close mx-2"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-center">
                <Auth handleClose={this.handleClose} />
              </div>
              <button
                id="closeAuthPopup"
                data-dismiss="modal"
                style={{ display: "none" }}
              ></button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthPopup;
