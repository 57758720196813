import React, { Component } from "react";
import axios from "axios";
import UploadingPopUp from "../UploadingPopUp";

class UpdateProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pic1: null,
      pic2: null,
      pic3: null,
      pic1Up: null,
      pic2Up: null,
      pic3Up: null,
      pic1Old: null,
      pic2Old: null,
      pic3Old: null,
      itemCode: "",
      addToNewArrival: false,
      addToNewArrivalSlideshow: false,
      addToSareeSlideshow: false,
      title: "",
      quantity: "",
      price: "",
      description: "",
      pendingItems: "",
      isuploadFailed: true,
      uploading: false,
      isProductFound: false,
      isInvalidItemcode: false,
      category: "office",
      isNoDiscount: false,
    };

    this.input = this.input.bind(this);
    this.uploadPic1 = this.uploadPic1.bind(this);
    this.uploadPic2 = this.uploadPic2.bind(this);
    this.uploadPic3 = this.uploadPic3.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.searchProduct = this.searchProduct.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  input(id) {
    document.getElementById(id).click();
  }
  uploadPic1 = (event) => {
    if (event.target.files[0].size > 102400) {
      alert("Image is too big! Max file size is 100KB.");
    } else {
      this.setState({
        pic1: event.target.files[0],
        pic1Up: URL.createObjectURL(event.target.files[0]),
      });
    }
  };
  uploadPic2 = (event) => {
    if (event.target.files[0].size > 819200) {
      alert("Image is too big! Max file size is 800KB.");
    } else {
      this.setState({
        pic2: event.target.files[0],
        pic2Up: URL.createObjectURL(event.target.files[0]),
      });
    }
  };
  uploadPic3 = (event) => {
    if (event.target.files[0].size > 819200) {
      alert("Image is too big! Max file size is 800KB.");
    } else {
      this.setState({
        pic3: event.target.files[0],
        pic3Up: URL.createObjectURL(event.target.files[0]),
      });
    }
  };
  searchProduct(event) {
    event.preventDefault();
    if (this.state.itemCode.length > 3) {
      axios
        .get("/products/" + this.state.itemCode)
        .then((res) => {
          if (res.data === null) {
            this.setState({
              isInvalidItemcode: true,
              isProductFound: false,
            });
          } else {
            this.setState({
              pic1Old: res.data.mainImage,
              pic2Old: res.data.image2,
              pic3Old: res.data.image3,
              pic1Up: res.data.mainImage,
              pic2Up: res.data.image2,
              pic3Up: res.data.image3,
              addToNewArrival: res.data.newArrival,
              addToNewArrivalSlideshow: res.data.newArrivalSideshow,
              addToSareeSlideshow: res.data.sareeSideshow,
              title: res.data.title,
              quantity: res.data.quantity,
              price: res.data.price,
              description: res.data.description,
              pendingItems: res.data.pendingItems,
              isProductFound: true,
              isInvalidItemcode: false,
              category: res.data.category ? res.data.category : "",
              isNoDiscount: res.data.isNoDiscount
                ? res.data.isNoDiscount
                : false,
            });
            document.getElementById("addToNewArrival").checked =
              res.data.newArrival;
            document.getElementById("addToNewArrivalSlideshow").checked =
              res.data.newArrivalSideshow;
            document.getElementById("addToSareeSlideshow").checked =
              res.data.sareeSideshow;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState({
        isInvalidItemcode: true,
        isProductFound: false,
      });
      console.log(this.state.itemCode);
    }
  }

  submitHandler(event) {
    event.preventDefault();
    document.getElementById("uploadingPopup").click();

    this.setState({ uploading: true });
    const data = new FormData();
    data.append("pic1", this.state.pic1);
    data.append("pic2", this.state.pic2);
    data.append("pic3", this.state.pic3);
    data.append("pic1Old", this.state.pic1Old);
    data.append("pic2Old", this.state.pic2Old);
    data.append("pic3Old", this.state.pic3Old);
    data.append("itemCode", this.state.itemCode);

    data.append("pendingItems", this.state.pendingItems);
    data.append("title", this.state.title);
    data.append("description", this.state.description);
    data.append("addToNewArrival", this.state.addToNewArrival);
    data.append(
      "addToNewArrivalSlideshow",
      this.state.addToNewArrivalSlideshow
    );
    data.append("addToSareeSlideshow", this.state.addToSareeSlideshow);
    data.append("quantity", this.state.quantity);
    data.append("price", this.state.price);
    data.append("category", this.state.category);
    data.append("isNoDiscount", this.state.isNoDiscount);
    axios
      .patch("/products/update", data, {
        headers: {
          authorization: localStorage.c,
        },
      })
      .then((res) => {
        console.log(res.statusText);
        this.setState({
          isuploadFailed: false,
          uploading: false,
          isProductFound: false,

          pic1: null,
          pic2: null,
          pic3: null,
          pic1Up: null,
          pic2Up: null,
          pic3Up: null,
          pic1Old: null,
          pic2Old: null,
          pic3Old: null,
          itemCode: "",
          addToNewArrival: false,
          addToNewArrivalSlideshow: false,
          addToSareeSlideshow: false,
          title: "",
          quantity: "",
          price: "",
          description: "",
          pendingItems: "",
          isInvalidItemcode: false,
          isNoDiscount: false,
          category: "",
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isuploadFailed: true,
          uploading: false,
        });
      });
  }
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid add-product page-body-admin">
          <div className="container-fluid main-title text-uppercase">
            <div className="row">
              <div className="col-12">Update Product</div>
            </div>
            <hr />
          </div>
          <div className="row">
            <div className="col-12">
              <form onSubmit={this.searchProduct}>
                {this.state.isInvalidItemcode ? (
                  <div className="alert alert-danger fade show" role="alert">
                    <strong>Product Not Found!</strong> Invalid item code.
                  </div>
                ) : null}
                <div className="search w-50 m-auto">
                  <label htmlFor="searchItem">Find Product : </label>
                  <input
                    type="text"
                    className="mx-4 rounded my-auto"
                    id="searchItem"
                    placeholder="Item Code"
                    value={this.state.itemCode}
                    onChange={(e) =>
                      this.setState({
                        itemCode: e.target.value.trim().toUpperCase(),
                      })
                    }
                    required
                  />
                  <button className="btn btn-dark btn-sm m-auto" type="submit">
                    search
                  </button>
                </div>
              </form>
              {this.state.isProductFound ? (
                <form>
                  <hr />
                  <div className="form-row mt-3">
                    <div className="form-group col-md-3">
                      <label htmlFor="inputTitle">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputTitle"
                        placeholder="Title"
                        value={this.state.title}
                        onChange={(e) =>
                          this.setState({ title: e.target.value })
                        }
                        required
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="impotItemCode">Item Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="impotItemCode"
                        placeholder="Item Code"
                        value={this.state.itemCode}
                        onChange={(e) =>
                          this.setState({ itemCode: e.target.value })
                        }
                        disabled
                        required
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="inputQuantity">Quantity</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputQuantity"
                        placeholder="Quantity"
                        value={this.state.quantity}
                        onChange={(e) =>
                          this.setState({ quantity: e.target.value.trim() })
                        }
                        required
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="inputQuantity">Price</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPrice"
                        placeholder="Price"
                        value={this.state.price}
                        onChange={(e) =>
                          this.setState({ price: e.target.value.trim() })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-sm-2">
                      <label htmlFor="inputPendingItems">Pending Items</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Count"
                        id="inputPendingItems"
                        value={this.state.pendingItems}
                        onChange={(e) =>
                          this.setState({ pendingItems: e.target.value.trim() })
                        }
                        required
                      />
                    </div>
                    <div className="form-group col-sm-3">
                      <label htmlFor="inputDescription">Description</label>
                      <textarea
                        className="form-control"
                        id="inputDescription"
                        rows="5"
                        value={this.state.description}
                        onChange={(e) =>
                          this.setState({ description: e.target.value })
                        }
                        required
                      ></textarea>
                    </div>

                    <div className="form-group col-sm-2 mx-2">
                      <label htmlFor="inputName">Main Image</label>
                      <div id="inputName" className="mx-2">
                        <div className="card w-100">
                          <div className="card-header text-center">
                            <span
                              className="btn btn-dark cursor-pointer"
                              onClick={() => this.input("input_btn1")}
                            >
                              +
                              <input
                                id="input_btn1"
                                className="btn btn-dark input_btn"
                                type="file"
                                onChange={this.uploadPic1}
                                required
                              />
                            </span>
                          </div>
                          <div className="card-body">
                            <img
                              id="pic1"
                              className=""
                              src={this.state.pic1Up}
                              alt=""
                              style={{ display: "block" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-sm-2 mx-2">
                      <label htmlFor="inputName">Image 2</label>
                      <div id="inputName" className="mx-2">
                        <div className="card w-100">
                          <div className="card-header text-center">
                            <span
                              className="btn btn-dark cursor-pointer"
                              onClick={() => this.input("input_btn2")}
                            >
                              +
                              <input
                                id="input_btn2"
                                className="btn btn-dark input_btn"
                                type="file"
                                onChange={this.uploadPic2}
                              />
                            </span>
                          </div>
                          <div className="card-body">
                            <img
                              id="pic2"
                              className=""
                              src={this.state.pic2Up}
                              alt=""
                              style={{ display: "block" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-sm-2 mx-2">
                      <label htmlFor="inputName">Image 3</label>
                      <div id="inputName" className="mx-2">
                        <div className="card w-100">
                          <div className="card-header text-center">
                            <span
                              className="btn btn-dark cursor-pointer"
                              onClick={() => this.input("input_btn3")}
                            >
                              +
                              <input
                                id="input_btn3"
                                className="btn btn-dark input_btn"
                                type="file"
                                onChange={this.uploadPic3}
                              />
                            </span>
                          </div>
                          <div className="card-body">
                            <img
                              id="pic3"
                              className=""
                              src={this.state.pic3Up}
                              alt=""
                              style={{ display: "block" }}
                            />
                          </div>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group form-check col-sm-3 col-md-3 pr-5">
                      <label htmlFor="category">Category</label>
                      <select
                        className="form-control"
                        id="category"
                        onChange={(e) =>
                          this.setState({ category: e.target.value })
                        }
                        defaultValue={this.state.category}
                      >
                        <option value=""></option>
                        <option value="designer">Designer Wear</option>
                        <option value="party">Party Wear</option>
                        <option value="office">Office Wear</option>
                        <option value="bridal">Bridal Wear</option>
                      </select>
                    </div>

                    <div className="form-group form-check col-sm-3 col-md-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="isNoDiscount"
                        onChange={(e) =>
                          this.setState({ isNoDiscount: e.target.checked })
                        }
                        checked={this.state.isNoDiscount}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isNoDiscount"
                      >
                        No Discount
                      </label>
                    </div>
                    <div className="form-group form-check col-sm-3 col-md-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="addToNewArrival"
                        onChange={(e) =>
                          this.setState({ addToNewArrival: e.target.checked })
                        }
                        checked={this.state.addToNewArrival}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="addToNewArrival"
                      >
                        Add To New Arrivals
                      </label>
                    </div>

                    <div className="form-group form-check col-sm-3 col-md-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="addToNewArrivalSlideshow"
                        onChange={(e) =>
                          this.setState({
                            addToNewArrivalSlideshow: e.target.checked,
                          })
                        }
                        checked={this.state.addToNewArrivalSlideshow}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="addToNewArrivalSlideshow"
                      >
                        Add To New Arrivals Slideshow
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="form-row text-center mx-auto">
                    <button
                      className="btn btn-dark mx-auto"
                      onClick={this.submitHandler}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              ) : null}
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#uploadingMessage"
                id="uploadingPopup"
                style={{ display: "none" }}
              ></button>
            </div>
          </div>
        </div>
        <UploadingPopUp
          isuploadFailed={this.state.isuploadFailed}
          uploading={this.state.uploading}
        />
      </React.Fragment>
    );
  }
}

export default UpdateProduct;
