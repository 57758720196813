import React, { Component } from "react";
class UploadingPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div
          className="modal fade"
          id="uploadingMessage"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="uploadingMessageLabel"
          aria-hidden="true"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body text-center">
                <h5 className="modal-title" id="uploadingMessageLabel">
                  Uploading..
                </h5>
                {!this.props.uploading ? (
                  this.props.isuploadFailed ? (
                    <h6 className="my-4">Uploading Failed!</h6>
                  ) : (
                    <h6 className="my-4">Successfully Uploaded..</h6>
                  )
                ) : (
                  <img src="../img/loading.gif" className="img-fluid" alt="" />
                )}

                <button
                  type="button"
                  className="btn btn-secondary m-auto"
                  data-dismiss="modal"
                  id="close"
                  style={
                    !this.props.uploading
                      ? this.props.isuploadFailed
                        ? {
                            display: "block",
                            background: "red"
                          }
                        : {
                            display: "block",
                            background: "green"
                          }
                      : { display: "none" }
                  }
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UploadingPopup;
