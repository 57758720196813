import React, { Component } from "react";
import axios from "axios";

class Offers extends Component {
  constructor(props) {
    super(props);
    this.state = { offers: [], error: false };
    axios
      .get("/offers/")
      .then(res => {
        if (res.data === null) {
          this.setState({
            error: true
          });
        } else {
          this.setState({
            offers: res.data,
            error: false
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="page-body container-fluid">
        <div className="container-fluid main-title text-uppercase">
          <div className="row">
            <div className="col-12">Offers</div>
          </div>
          <hr />
        </div>

        <div className="row">
          {this.state.offers.map((offer, index) => {
            if (offer.available) {
              return (
                <div
                  key={index}
                  className="col-12 col-sm-6 col-md-4 text-center px-3"
                >
                  <div className="card">
                    <div className="card-header">
                      <span className="text-warning">New</span>
                    </div>
                    <img
                      src={offer.image}
                      className="mx-auto d-block m-1"
                      alt="offers1"
                      style={{ maxHeight: "14rem" }}
                    />
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    );
  }
}

export default Offers;
