import React, { Component } from "react";
class Delivery extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="page-body container-fluid delivery">
        <div className="container-fluid main-title text-uppercase">
          <div className="row">
            <div className="col-12">Delivery Details</div>
          </div>
          <hr />
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <div
              className="c p-3"
              style={{
                width: "70%",
                border: "solid 1px gray",
                borderRadius: "20px",
                margin: "auto",
              }}
            >
              We provide island-wide FREE delivery for all orders.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Delivery;
