import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authAction";
import "../../css/Navbar.css";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.handleSearch = this.handleSearch.bind(this);
    this.state = {
      searchText: "",
    };
  }
  handleSearch = (e) => {
    e.preventDefault();
    let path = "/search/" + this.state.searchText.trim().split(" ").join("+");
    this.props.history.push(path);
  };
  render() {
    return (
      <nav
        id="navKlover"
        className="navbar navbar-expand-md navbar-dark bg-dark pt-1 pb-0 mb-0 px-auto fixed-top "
      >
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="text-center" style={{ minWidth: "150px" }}>
          <Link to="/" className="text-center">
            <img
              className="nav-logo img-fluid m-auto"
              src="/img/logo_white.png"
              alt="klover"
              width="100"
            />
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent2"
          aria-controls="navbarSupportedContent2"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-user fa-lg p-1" />
        </button>
        <div
          className="collapse flex-column navbar-collapse m-auto "
          id="navbarSupportedContent"
          data-parent="#navKlover"
        >
          <div className="search-box mx-auto w-100 mt-1">
            <form onSubmit={this.handleSearch}>
              <input
                type="text"
                className="search-txt"
                value={this.state.searchText}
                onChange={(e) => this.setState({ searchText: e.target.value })}
              />
              <button
                style={{ border: "none" }}
                className="search-btn"
                type="submit"
              >
                <i
                  className="fas fa-search"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                />
              </button>
            </form>
          </div>

          <div className="d-flex justify-content-center w-100 ">
            <div className="nav-dropdown m-auto  text-center ">
              <span className="m-auto pt-1  nav-link text-center ">Sarees</span>
              <div
                className="nav-dropdown-content dropdown-menu-left text-left"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
              >
                <div>
                  <Link to="/designer-wear" className="dropdown-item text-left">
                    Designer Wear
                  </Link>
                  <Link to="/party-wear" className="dropdown-item text-left">
                    Party Wear
                  </Link>{" "}
                  <Link to="/bridal-wear" className="dropdown-item text-left">
                    Bridal Wear
                  </Link>
                  <Link to="/office-wear" className="dropdown-item text-left">
                    Office Wear
                  </Link>{" "}
                </div>
              </div>
            </div>
            <span
              className="m-auto "
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
            >
              <Link
                to="/newArrivals"
                className="m-auto pt-1  nav-link text-center "
              >
                New Arrivals
              </Link>
            </span>
            <span
              className="m-auto "
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
            >
              <Link to="/offers" className="m-auto pt-1  nav-link text-center">
                Offers
              </Link>
            </span>
            <span
              data-toggle="collapse"
              className="m-auto"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
            >
              <Link
                to="/contactus"
                className="m-auto pt-1  nav-link text-center"
              >
                Contact Us
              </Link>
            </span>
          </div>
        </div>

        <div
          className="collapse flex-column navbar-collapse m-auto nav-c-item"
          id="navbarSupportedContent2"
          data-parent="#navKlover"
        >
          <div className="d-flex justify-content-center">
            <div className="nav-dropdown">
              <button className="p-2 nav-dropbtn">
                <i className="fas fa-user fa-lg" />
              </button>
              <div
                className="nav-dropdown-content dropdown-menu-left"
                data-toggle="collapse"
                data-target="#navbarSupportedContent2"
                aria-controls="navbarSupportedContent2"
              >
                {this.props.isLoggedIn ? (
                  <div>
                    <div
                      className="text-center"
                      style={{
                        borderRadius: "3.5rem",
                        overflow: "hidden",
                        width: "3.5rem",
                        height: "3.5rem",
                        margin: "auto",
                      }}
                    >
                      <img
                        className="img-fluid m-auto"
                        src="/img/profile.jpg"
                        alt="profile"
                        style={{ width: "3.5rem" }}
                      />
                    </div>
                    <div className="text-center">
                      {this.props.currentUser.name}
                    </div>
                    <div className="dropdown-divider"></div>

                    <Link to="/myorders" className="dropdown-item">
                      <button className="dropdown-item">
                        <strong>Orders</strong>
                      </button>
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link to="/" className="dropdown-item">
                      <button
                        onClick={this.props.logoutUser}
                        className="dropdown-item"
                      >
                        <strong>Log Out</strong>
                      </button>
                    </Link>
                  </div>
                ) : (
                  <Link to="/auth" className="dropdown-item btn-dark btn">
                    Login / Signup
                  </Link>
                )}
              </div>
            </div>
            <button
              style={{ background: "none", border: "none" }}
              onClick={() =>
                this.props.isLoggedIn
                  ? null
                  : document.getElementById("authpopupDetails").click()
              }
            >
              {this.props.isLoggedIn ? (
                <Link to="/wishlist" className="px-3 py-2 wish-list">
                  <i
                    className="fas fa-heart fa-lg"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent2"
                    aria-controls="navbarSupportedContent2"
                  />
                </Link>
              ) : (
                <span className="px-3 py-2 wish-list">
                  <i
                    className="fas fa-heart fa-lg"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent2"
                    aria-controls="navbarSupportedContent2"
                  />
                </span>
              )}
            </button>
            <button
              style={{ background: "none", border: "none" }}
              className="nav-dropdown"
              onClick={() =>
                this.props.isLoggedIn
                  ? null
                  : document.getElementById("authpopupDetails").click()
              }
            >
              {this.props.isLoggedIn ? (
                <Link to="/cart" className="px-3 py-2 nav-dropbtn">
                  <i
                    className="fas fa-shopping-cart fa-lg"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent2"
                    aria-controls="navbarSupportedContent2"
                  />
                </Link>
              ) : (
                <span className="px-3 py-2 nav-dropbtn">
                  <i
                    className="fas fa-shopping-cart fa-lg"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent2"
                    aria-controls="navbarSupportedContent2"
                  />
                </span>
              )}
            </button>

            {
              //   <div className="nav-dropdown ">
              //   <button className="pl-2 py-2 nav-dropbtn">
              //     <i className="fas fa-shopping-cart fa-lg" />
              //   </button>
              //   <div className="nav-dropdown-content dropdown-menu-right">
              //     {
              //       // <div className="dropdown-item">Total :- Rs:5000</div>
              //     }
              //     <button
              //       onClick={() =>
              //         this.props.isLoggedIn
              //           ? null
              //           : document.getElementById("authpopupDetails").click()
              //       }
              //       className="checkout"
              //       style={{ border: "none", background: "none" }}
              //     >
              //       <Link
              //         to={this.props.isLoggedIn ? "/cart" : null}
              //         className="checkout-btn mx-4"
              //       >
              //         checkout
              //       </Link>
              //     </button>
              //   </div>
              // </div>
            }
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  currentUser: state.auth.currentUser,
});

export default withRouter(connect(mapStateToProps, { logoutUser })(Navbar));
