import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../css/Footer.css";

class Footer extends Component {
  componentDidMount() {
    if (window.screen.width > 700) {
      document.getElementById("facebook").classList.add("fa-3x");
      document.getElementById("instagram").classList.add("fa-3x");
    } else {
      document.getElementById("facebook").classList.add("fa-2x");
      document.getElementById("instagram").classList.add("fa-2x");
    }
  }
  render() {
    return (
      <footer className="page-footer font-small pt-3 badge-dark ">
        <div className="container text-left">
          <div className="row text-left mt-2 pb-2">
            <div className="col-12 col-sm-5 mx-auto my-2 mb-3">
              <div>
                <img
                  className="footer-logo img-fluid "
                  src="/img/logo_white.png"
                  alt="klover"
                />
              </div>
              <br />
              <div className="mx-1">
                A range of designer wear sarees, enchanting true modern
                spirits..
              </div>
            </div>

            <div className="col-5 col-sm-3 mx-auto mt-2">
              <h6 className="text-uppercase mb-3 font-weight-bold">
                Useful links
              </h6>
              <div className="my-2">
                <Link to="/orderTracking">Track Order</Link>
              </div>

              <div className="my-2">
                <Link to="/delivery">Delivery</Link>
              </div>
              <div className="my-2">
                <Link to="/privacy">Privacy Policy</Link>
              </div>
              <div className="my-2">
                <Link to="/contactus">Contact Us</Link>
              </div>
            </div>

            <div className="col-7 col-sm-4 mx-auto mt-2">
              <h6 className="text-uppercase mb-3 font-weight-bold">Contact</h6>
              <div className="my-2">
                <i className="fas fa-home mr-1"></i> No : 51/B, Kanduboda,
                Delgoda.
              </div>
              <div className="my-2">
                <i className="fas fa-envelope mr-1"></i> klovergroups@gmail.com
              </div>
              <div className="my-2">
                <i className="fas fa-phone mr-1"></i> 0112489629 / 0719643899
              </div>
              <div className="my-2">
                <i className="fas fa-clock mr-1"></i> Open : Everyday 7.00A.M -
                10.00P.M
              </div>
            </div>
          </div>
          <hr />

          <div className="row d-flex py-auto pb-2">
            <div className="col-5 col-sm-6 copyright my-auto">
              <div className="row no-gutters ">
                <div className="col-sm-6 col-12 text-right">
                  © 2021
                  <strong> KLOVER</strong>
                </div>
                <div className="col-sm-6 col-12 text-left offset-sm-0 pl-2">
                  <small>All Rights Reserved</small>
                </div>
              </div>
            </div>

            <div className="col-3 col-sm-3 text-right my-auto">
              <div className="my-1">Follow Us On :</div>
            </div>

            <div className="col-4 col-sm-3 text-left social-icon ">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/kloversaree/"
                className="facebook text-center"
              >
                <i id="facebook" className="fab fa-facebook"></i>
              </a>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/kloversaree/"
                className="instagram text-center"
              >
                <i id="instagram" className="fab fa-instagram "></i>
              </a>
            </div>
            <div className="col-12 col-sm-0"></div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
