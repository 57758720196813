import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Deafault from "./pages/Default";
import AddProduct from "./admin/addProduct/AddProduct";
import Navbar from "./admin/Navbar";
import UpdateProduct from "./admin/updateProducts/UpdateProduct";
import Customizer from "./admin/Customizer";
import Orders from "./admin/orders/Orders";
import { connect } from "react-redux";
import { Redirect } from "react-router";

class Admin extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar />
        {!this.props.currentUser.is_admin ? <Redirect to="/auth" /> : null}
        <Switch>
          <Route path="/admin/addproduct" component={AddProduct} />
          <Route path="/admin/customize" component={Customizer} />
          <Route exact path="/admin/" component={Orders} />
          <Route path="/admin/updateproduct" component={UpdateProduct} />
          <Route component={Deafault}></Route>
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser
});

export default connect(mapStateToProps, {})(Admin);
