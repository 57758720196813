import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./../../css/Product.css";
import axios from "axios";
import { connect } from "react-redux";
import { handleAddToCart } from "../../actions/addToCartAction";

class Product extends Component {
  handleWishList(itemCode) {
    axios
      .patch(
        "/users/addToWishlist",
        {
          userID: this.props.currentUser.user_id,
          wishItem: {
            itemCode: itemCode,
          },
        },
        {
          headers: {
            authorization: localStorage.c,
          },
        }
      )
      .then((response) => {
        console.log(itemCode + " added to wishlist");
      })
      .catch((err) => {
        console.log("Error:" + err);
      });
  }

  handleCart = () => {
    // if (!this.state.cart.includes(this.state.product.itemCode)) {

    this.props.handleAddToCart({
      userID: this.props.currentUser.user_id,
      product: this.props.product,
      quantity: 1,
      total: this.props.product.price,
    });
    document.getElementById("popupcart").click();
    // } else {
    //   console.log("already in cart");

    // }
  };

  render() {
    const { mainImage, price, itemCode, isNoDiscount, title } =
      this.props.product;
    return (
      <div className="card text-center mx-1 w-100">
        <div className="img-container align-middle m-1">
          <Link to={"/details/" + this.props.product.itemCode}>
            <img
              src={mainImage}
              alt={`${title}-${itemCode}`}
              className="card-img-top"
            />
          </Link>
          <button
            title="Add to wishList"
            onClick={() =>
              this.props.isLoggedIn
                ? this.handleWishList(itemCode)
                : document.getElementById("authpopupDetails").click()
            }
            className="wish-btn"
          >
            <i className="fas fa-heart" />
          </button>
          <button
            title="Add to cart"
            onClick={() =>
              this.props.isLoggedIn
                ? this.handleCart()
                : document.getElementById("authpopupDetails").click()
            }
            className="cart-btn"
          >
            <i className="fas fa-cart-plus position-static" />
          </button>
        </div>

        <div className="card-footer d-flex justify-content-between px-2">
          <h6 className="align-self-center mb-0">{itemCode}</h6>
          <h6 className="text-blue font-italic mb-0">
            <span className="mr-1">Rs:</span>
            {(price * (100 - (isNoDiscount ? 5 : this.props.discount + 5))) /
              100}
          </h6>

          <del
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              paddingRight: "1rem",
              fontSize: "0.8rem",
              color: "#b30000",
            }}
          >
            Rs: {price}
          </del>
        </div>
        <button
          data-toggle="modal"
          data-target="#addToCartPopup"
          type="button"
          id="popupcart"
          style={{ display: "none" }}
        ></button>
        {!isNoDiscount && this.props.discount > 0 && (
          <div style={{ position: "absolute" }}>
            <img
              src={this.props.discountImage}
              alt="promo"
              style={{ height: "4rem", zIndex: 1000 }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps, { handleAddToCart })(Product);
