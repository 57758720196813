import React, { Component } from "react";
import UploadingPopUp from "./UploadingPopUp";
import axios from "axios";

class Customizer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pic1: null,
      pic1Up: null,
      pic2: null,
      pic2Up: null,
      pic3: null,
      pic3Up: null,
      pic1Old: "",
      pic2Old: "",
      pic3Old: "",
      id: "",
      uploading: false,
      error: false,
      offers: [],
      newOfferImage: "",
      newOfferstate: false,
      newOfferImageFile: "",
      discount: 0,
      discountImage: "",
      discountImageFile: "",
      oldDiscountImage: "",
    };

    this.input = this.input.bind(this);
    this.uploadDiscountPic = this.uploadDiscountPic.bind(this);
    this.updateDiscountImageHandler =
      this.updateDiscountImageHandler.bind(this);
    this.uploadPic1 = this.uploadPic1.bind(this);
    this.uploadOfferPic = this.uploadOfferPic.bind(this);
    this.uploadPic2 = this.uploadPic2.bind(this);
    this.uploadPic3 = this.uploadPic3.bind(this);
    this.slideshowsubmitHandler = this.slideshowsubmitHandler.bind(this);
    this.offerUpdateHandler = this.offerUpdateHandler.bind(this);
    this.discountUpdateHandler = this.discountUpdateHandler.bind(this);
    this.addOfferHandler = this.addOfferHandler.bind(this);
    this.loadData();
  }
  loadData() {
    axios
      .get("/slideshow/")
      .then((res) => {
        if (res.data === null) {
          this.setState({
            error: true,
          });
        } else {
          this.setState({
            pic1Old: res.data.image1,
            pic2Old: res.data.image2,
            pic3Old: res.data.image3,
            pic1Up: res.data.image1,
            pic2Up: res.data.image2,
            pic3Up: res.data.image3,
            id: res.data._id,
            error: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("/offers/")
      .then((res) => {
        if (res.data === null) {
          this.setState({
            error: true,
          });
        } else {
          this.setState({
            offers: res.data,
            error: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("/settings/")
      .then((res) => {
        if (res.data === null) {
          this.setState({
            error: true,
          });
        } else {
          // console.log(res);
          this.setState({
            discount: res.data.discount,
            discountImage: res.data.discountImage,
            oldDiscountImage: res.data.discountImage,
            error: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  input(id) {
    document.getElementById(id).click();
  }
  handleOffercheck(index) {
    var offers = this.state.offers;

    offers[index].available = document.getElementById(
      "available" + index
    ).checked;
    this.setState({
      offers: offers,
    });
  }
  uploadOfferPic(event) {
    this.setState({
      newOfferImage: URL.createObjectURL(event.target.files[0]),
      newOfferImageFile: event.target.files[0],
    });
  }
  uploadPic1 = (event) => {
    this.setState({
      pic1: event.target.files[0],
      pic1Up: URL.createObjectURL(event.target.files[0]),
    });
  };
  uploadPic2 = (event) => {
    this.setState({
      pic2: event.target.files[0],
      pic2Up: URL.createObjectURL(event.target.files[0]),
    });
  };
  uploadPic3 = (event) => {
    this.setState({
      pic3: event.target.files[0],
      pic3Up: URL.createObjectURL(event.target.files[0]),
    });
  };
  slideshowsubmitHandler(event) {
    document.getElementById("uploadingPopup").click();

    this.setState({ uploading: true });
    const data = new FormData();
    data.append("image1", this.state.pic1);
    data.append("image2", this.state.pic2);
    data.append("image3", this.state.pic3);
    data.append("image1Old", this.state.pic1Old);
    data.append("image2Old", this.state.pic2Old);
    data.append("image3Old", this.state.pic3Old);
    data.append("_id", this.state.id);
    axios
      .patch("/slideshow/update", data, {
        headers: {
          authorization: localStorage.c,
        },
      })
      .then((res) => {
        console.log(res.statusText);
        this.loadData();
        this.setState({
          isuploadFailed: false,
          uploading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isuploadFailed: true,
          uploading: false,
        });
      });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  addOfferHandler() {
    console.log(this.state.newOfferstate);
    document.getElementById("uploadingPopup").click();
    this.setState({ uploading: true });

    const data = new FormData();
    data.append("image", this.state.newOfferImageFile);
    data.append("available", this.state.newOfferstate);
    axios
      .post("/offers/addNew", data, {
        headers: {
          authorization: localStorage.c,
        },
      })
      .then((res) => {
        console.log(res.statusText);
        this.loadData();
        this.setState({
          isuploadFailed: false,
          uploading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isuploadFailed: true,
          uploading: false,
        });
      });
  }
  offerUpdateHandler(index) {
    document.getElementById("uploadingPopup").click();
    this.setState({ uploading: true });

    axios
      .patch(
        "/offers/update",
        {
          _id: this.state.offers[index]._id,
          available: this.state.offers[index].available,
        },
        {
          headers: {
            authorization: localStorage.c,
          },
        }
      )
      .then((res) => {
        console.log(res.statusText);
        this.loadData();
        this.setState({
          isuploadFailed: false,
          uploading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isuploadFailed: true,
          uploading: false,
        });
      });
  }
  discountUpdateHandler() {
    document.getElementById("uploadingPopup").click();
    this.setState({ uploading: true });

    axios
      .patch(
        "/settings/updateDiscount",
        {
          amount: this.state.discount,
        },
        {
          headers: {
            authorization: localStorage.c,
          },
        }
      )
      .then((res) => {
        console.log(res.statusText);
        this.loadData();
        this.setState({
          isuploadFailed: false,
          uploading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isuploadFailed: true,
          uploading: false,
        });
      });
  }
  uploadDiscountPic(event) {
    if (event.target.files[0].size > 2097152) {
      alert("Image is too big! Max file size is 2MB.");
    } else {
      this.setState({
        discountImage: URL.createObjectURL(event.target.files[0]),
        discountImageFile: event.target.files[0],
      });
    }
  }
  updateDiscountImageHandler() {
    document.getElementById("uploadingPopup").click();
    this.setState({ uploading: true });

    const data = new FormData();
    data.append("image", this.state.discountImageFile);
    data.append("imageOld", this.state.oldDiscountImage);
    axios
      .patch("/settings/updateDiscountImage", data, {
        headers: {
          authorization: localStorage.c,
        },
      })
      .then((res) => {
        console.log(res.statusText);
        this.loadData();
        this.setState({
          isuploadFailed: false,
          uploading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isuploadFailed: true,
          uploading: false,
        });
      });
  }
  render() {
    return (
      <div className="page-body-admin container-fluid">
        <div className="container-fluid main-title text-uppercase">
          <div className="row">
            <div className="col-12">Update Website</div>
          </div>
          <hr />
        </div>
        <div className="row">
          <div
            className="col-12 text-left mx-4 p-3"
            style={{ border: "1px solid gray", borderRadius: "10px" }}
          >
            <h5>Update Main Slideshow Images</h5>
            {this.state.error ? (
              <div className="alert alert-danger fade show" role="alert">
                <strong>Unable to find Slideshow!</strong> Database Error.
              </div>
            ) : null}
            <div className="row text-center px-5">
              <div className="col-sm-4  w-100">
                <div className="card-header text-center">
                  <span
                    className="btn btn-dark cursor-pointer"
                    onClick={() => this.input("input_btn1")}
                  >
                    +
                    <input
                      id="input_btn1"
                      className="btn btn-dark input_btn"
                      type="file"
                      onChange={this.uploadPic1}
                    />
                  </span>
                </div>
                <div className="card-body">
                  <img
                    id="pic1"
                    className="img-fluid"
                    src={this.state.pic1Up}
                    alt=""
                    style={{ display: "block" }}
                  />
                </div>
                <hr />
              </div>
              <div className="col-sm-4  w-100">
                <div className="card-header text-center">
                  <span
                    className="btn btn-dark cursor-pointer"
                    onClick={() => this.input("input_btn2")}
                  >
                    +
                    <input
                      id="input_btn2"
                      className="btn btn-dark input_btn"
                      type="file"
                      onChange={this.uploadPic2}
                    />
                  </span>
                </div>
                <div className="card-body">
                  <img
                    id="pic2"
                    className="img-fluid"
                    src={this.state.pic2Up}
                    alt=""
                    style={{ display: "block" }}
                  />
                </div>
                <hr />
              </div>
              <div className="col-sm-4  w-100">
                <div className="card-header text-center">
                  <span
                    className="btn btn-dark cursor-pointer"
                    onClick={() => this.input("input_btn3")}
                  >
                    +
                    <input
                      id="input_btn3"
                      className="btn btn-dark input_btn"
                      type="file"
                      onChange={this.uploadPic3}
                    />
                  </span>
                </div>
                <div className="card-body">
                  <img
                    id="pic3"
                    className="img-fluid"
                    src={this.state.pic3Up}
                    alt=""
                    style={{ display: "block" }}
                  />
                </div>
                <hr />
              </div>
            </div>
            <div className="row text-center">
              <div className="col-6 m-auto">
                <button
                  className="btn btn-dark m-auto"
                  onClick={this.slideshowsubmitHandler}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div
            className="col-12 text-left mx-4 p-3"
            style={{ border: "1px solid gray", borderRadius: "10px" }}
          >
            <h5>Update Settings</h5>

            <div className="row text-center px-5 m-3">
              <div className="col-4">Discount - </div>
              <div className="col-4">
                <input
                  type="number"
                  className="form-control"
                  placeholder="amount"
                  value={this.state.discount}
                  onChange={(e) =>
                    this.setState({ discount: parseFloat(e.target.value) })
                  }
                  required
                />
              </div>

              <div className="col-4 m-auto">
                <button
                  className="btn btn-dark btn-sm m-auto"
                  onClick={this.discountUpdateHandler}
                >
                  Set Amount
                </button>
              </div>
              <div className="col-4"></div>
              <div className="col-4">
                <div className="card-header text-center">
                  <span
                    className="btn btn-dark cursor-pointer"
                    onClick={() => this.input("input_discountImg")}
                  >
                    +
                    <input
                      id="input_discountImg"
                      className="btn btn-dark input_btn"
                      type="file"
                      onChange={this.uploadDiscountPic}
                    />
                  </span>
                </div>
                <div className="card-body">
                  <img
                    // id="pic2"
                    className="img-fluid"
                    src={this.state.discountImage}
                    alt=""
                    style={{ display: "block" }}
                  />
                </div>
                <hr />
              </div>
              <div className="col-4 m-auto">
                <button
                  disabled={!this.state.discountImageFile}
                  className="btn btn-dark btn-sm m-auto"
                  onClick={this.updateDiscountImageHandler}
                >
                  Set Image
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div
            className="col-12 text-left mx-4 p-3"
            style={{ border: "1px solid gray", borderRadius: "10px" }}
          >
            <h5>Update Offers</h5>
            {this.state.error ? (
              <div className="alert alert-danger fade show" role="alert">
                <strong>Unable to find Offers!</strong> Database Error.
              </div>
            ) : null}
            <div
              className="row text-center px-5 m-3"
              style={{
                border: "1px solid gray",
                borderRadius: "10px",
              }}
            >
              <div className="col-4">
                <div className="card-header text-center">
                  <span
                    className="btn btn-dark cursor-pointer"
                    onClick={() => this.input("inputoffer")}
                  >
                    +
                    <input
                      id="inputoffer"
                      className="btn btn-dark input_btn"
                      type="file"
                      onChange={this.uploadOfferPic}
                    />
                  </span>
                </div>
                <div className="card-body">
                  <img
                    id="pic2"
                    className="img-fluid"
                    src={this.state.newOfferImage}
                    alt=""
                    style={{ display: "block" }}
                  />
                </div>
              </div>
              <div className="col-4 m-auto">
                <div className="">
                  <label className="mx-3 my-auto" htmlFor="availableNew">
                    <strong>Available :</strong>
                  </label>
                  <input
                    className="my-auto"
                    type="checkbox"
                    id="availableNew"
                    style={{ transform: "scale(1.5)" }}
                    checked={this.state.newOfferstate}
                    onChange={(e) =>
                      this.setState({ newOfferstate: e.target.checked })
                    }
                  />
                </div>
              </div>
              <div className="col-4 m-auto">
                <button
                  className="btn btn-dark m-auto"
                  onClick={this.addOfferHandler}
                >
                  Add
                </button>
              </div>
            </div>

            {this.state.offers.map((offer, index) => {
              return (
                <div
                  key={index}
                  className="row text-center px-5 m-3"
                  style={{
                    border: "1px solid gray",
                    borderRadius: "10px",
                  }}
                >
                  <div className="col-4 my-1">
                    <img
                      className=""
                      src={offer.image}
                      style={{ height: "120px", width: "auto" }}
                      alt="klover"
                    />
                  </div>
                  <div className="col-4 m-auto">
                    <div className="">
                      <label
                        className="mx-3 my-auto"
                        htmlFor={"available" + index}
                      >
                        <strong>Available :</strong>
                      </label>
                      <input
                        className="my-auto"
                        type="checkbox"
                        id={"available" + index}
                        style={{ transform: "scale(1.5)" }}
                        checked={offer.available}
                        onChange={() => this.handleOffercheck(index)}
                      />
                    </div>
                  </div>
                  <div className="col-4 m-auto">
                    <button
                      className="btn btn-dark m-auto"
                      onClick={() => this.offerUpdateHandler(index)}
                    >
                      Update
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <button
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#uploadingMessage"
          id="uploadingPopup"
          style={{ display: "none" }}
        ></button>{" "}
        <UploadingPopUp
          isuploadFailed={this.state.isuploadFailed}
          uploading={this.state.uploading}
        />
      </div>
    );
  }
}

export default Customizer;
