import React, { Component } from "react";
import "../../css/Login.css";
import { connect } from "react-redux";
import { handleLogin } from "../../actions/authAction";
import Auth from "./Auth";
import { Redirect } from "react-router";

class AuthMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      close: false
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleClose = this.handleClose.bind(this);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  componentDidMount() {
    this.updateWindowDimensions();

    window.addEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ height: window.innerHeight });
  }
  handleClose() {
    this.setState({ close: true });
  }

  render() {
    return (
      <div
        className="container-fluid badge-dark pb-3"
        style={{
          minHeight: this.state.height + "px",
          backgroundImage: "url(/img/klover-5.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
          padding: this.state.height / 5 + "px 0 0 0 "
        }}
      >
        {this.state.close ? <Redirect to="/" /> : null}
        <Auth handleClose={this.handleClose} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  userNotFound: state.auth.userNotFound,
  loginSuccess: state.auth.loginSuccess
});

export default connect(mapStateToProps, { handleLogin })(AuthMain);
