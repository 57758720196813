import React from "react";
import { Switch, Route } from "react-router-dom";
import Navbar from "./common/Navbar";
import Home from "./home/Home";
import Footer from "./common/Footer";
import Offers from "./Offers";
import NewArrivals from "./NewArrivals";
import Details from "./Details";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Delivery from "./pages/Delivery";
import Cart from "./orderManagement/Cart";
import WishList from "./orderManagement/WishList";
import MyOrders from "./orderManagement/MyOrders";
import PlaceOrder from "./orderManagement/placeOrder/PlaceOrder";
import Deafault from "./pages/Default";
import TrackOrder from "./pages/TrackOrder";
import Search from "./Search";
import PartyWear from "./PartyWear";
import OfficeWear from "./OfficeWear";
import DesignerWear from "./DesignerWear";
import BridalWear from "./BridalWear";

function User(props) {
  return (
    <React.Fragment>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/offers" component={Offers} />
        <Route exact path="/newarrivals" component={NewArrivals} />
        <Route exact path="/party-wear" component={PartyWear} />
        <Route exact path="/office-wear" component={OfficeWear} />
        <Route exact path="/designer-wear" component={DesignerWear} />
        <Route exact path="/bridal-wear" component={BridalWear} />
        <Route path="/details/:id" component={Details} />
        <Route exact path="/contactus" component={ContactUs} />
        <Route exact path="/privacy" component={PrivacyPolicy} />
        <Route exact path="/delivery" component={Delivery} />
        <Route path="/ordertracking/:id" component={TrackOrder} />
        <Route path="/ordertracking" component={TrackOrder} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/wishlist" component={WishList} />
        <Route exact path="/myorders" component={MyOrders} />
        <Route exact path="/placeorder" component={PlaceOrder} />
        <Route path="/search/:id" component={Search} />
        <Route component={Deafault}></Route>
      </Switch>
      <Footer />
    </React.Fragment>
  );
}
export default User;
